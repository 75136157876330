import React from "react";
import { Select } from "@mui/material";

const MySelect = ({ children, form, field, ...props }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      fullWidth
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
      disabled={props.disabled}
    >
      {children}
    </Select>
  );
};

export default MySelect;
