import { Autocomplete, Box, Button, CircularProgress, FormControl, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import { DateNavigator, DayView, Toolbar } from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState, EditingState, IntegratedEditing } from "@devexpress/dx-react-scheduler";
import { Scheduler, WeekView, MonthView, Appointments, AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import ContentBox from "../../components/ui/ContentBox";
import { modalStyle, modalStyleOverflow, modalStyleVertical } from "../../theme/ModalStyle";
import ButtonSquare from "../../components/ui/ButtonSquare";
import moment from "moment";
import PersonIcon from "@mui/icons-material/Person";
import { useLocation, useParams } from "react-router-dom";
import { Urls } from "../../api/Urls";

import * as yup from "yup";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAuthContext } from "../../hooks/useAuthContext";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { ErrorMessage, Field, Form, Formik, yupToFormErrors } from "formik";
import { hours_time } from "../../components/data/hours";
import Api from "../../api/Api";
import { debounce } from "lodash";

import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TableShowUser from "../../components/ui/TableShowUser";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import BtaDatePicker from "../../components/ui/BtaDatePicker";
import MySwitch from "../../components/ui/MySwitch/MySwitch";
import { colors } from "../../theme/Colors";

function PlayersSchedule({}) {
  const [currentViewName, setCurrentViewName] = useState("Week");
  const [court, setCourt] = useState(null);
  const [term, setTerm] = useState();
  const [terms, setTerms] = useState([]);
  const [courts, setCourts] = useState([]);
  const [openCancelSession, setOpenCancelSession] = useState(false);
  const [scheduleAdded, setScheduleAdded] = useState([]);
  const [addedScheduleToshow, setAddedScheduleToshow] = useState({
    open: false,
    data: null,
  });
  const [selectedType, setSelectedType] = useState("new");
  const [programs, setPrograms] = useState([]);
  const [coachData, setCoachData] = useState(null);
  const [sessionType, setSessionType] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [players, setPlayers] = useState([]);
  const { user } = useAuthContext();
  const [showCanceledSlots, setShowCanceledSlots] = useState(false);

  const access_token = user?.access_token;
  const [visible, setVisible] = useState(false);
  const [loadingCoach, setLoadingCoach] = useState(true);
  const location = useLocation();
  const id = location?.state?.id ? location?.state?.id : null;
  const data = location?.state?.data ? location?.state?.data : null;
  const [program, setProgram] = useState("");
  const [openDetails, setOpenDetails] = useState({
    open: false,
    data: null,
  });
  const [initialValuesNewPlayer, setInitialValuesNewPlayer] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
  });
  const validationSchemaNewPlayer = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    gender: yup.string().required("this field is mandatory"),
    date_of_birth: yup.date().required("this field is mandatory"),
  });
  const validationSchemaExistingPlayer = yup.object({
    user_name: yup.string().required("this field is mandatory"),
  });
  const [initialValuesExistantPlayer, setInitialValuesExistantPlayer] = useState({
    user_name: null,
    id: "",
  });
  useEffect(() => {
    document.title = "BTA admin - Players schedule";
  }, []);
  useEffect(() => {
    console.log(term, "term", selectedDate);
    if (term) {
      setSelectedDate(new Date(term?.start_date) < new Date() && new Date(term?.end_date) >= new Date() ? new Date() : new Date(term?.start_date));
    }
  }, [term]);
  useEffect(() => {
    if (data !== null) {
      handleClickAppointment(data);
    }
  }, []);
  //get list players
  const searchPlayer = (search) => {
    Api.get(Urls.GET_PLAYERS_LIST, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(search && { search: search }),
      },
    })
      .then((response) => {
        setPlayers(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  //background color & text color management for schedules
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex?.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };
  const handleClickAppointementPlus = (data) => {
    const array = scheduleAdded.filter((schedule) => schedule.startDate.split(" ")[0] === data?.startDate.split(" ")[0]);
    const array_2 = schedule.filter((schedule) => schedule.startDate.split(" ")[0] === data?.startDate.split(" ")[0] && !schedule?.new);
    const global_array = array_2.concat(array);
    setAddedScheduleToshow({
      open: true,
      data: global_array,
    });
  };
  const handleCancelAppointement = (bool) => {
    Api.post(
      Urls.CANCEL_APPOINTEMENT + openDetails?.data?.id + "/cancel",
      {
        refund: bool ? 1 : 0,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success("Canceled");
        setChange(!change);
        setOpenCancelSession(false);
        setOpenDetails({
          open: false,
          data: null,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  //reorganize data in better shape to be used
  const limitOccurenceNumber = (data, cancelled) => {
    let reorganizedArray = [];
    let addedArray = [];
    for (let index = 0; index < data.length; index++) {
      let count = 1;
      const element = data[index];
      reorganizedArray.push(element);
      for (let i = index + 1; i < data.length; i++) {
        const elementToCompare = data[i];
        if (element?.startDate.split(" ")[0] === elementToCompare?.startDate.split(" ")[0] && count < 2) {
          reorganizedArray.push(elementToCompare);
          count = count + 1;
          if (i === data?.length - 1) {
            data = data?.filter((da) => da?.startDate?.split(" ")[0] !== element?.startDate?.split(" ")[0]);
            data.unshift(null);
          }
        } else {
          if (count === 2 && element?.startDate.split(" ")[0] === elementToCompare?.startDate.split(" ")[0]) {
            reorganizedArray.push({ ...element, new: true, startDate: element?.startDate?.split(" ")[0] + " 23:59:00", endDate: element?.endDate?.split(" ")[0] + " 23:59:10" });
            count = count + 1;
          }
          if (count > 2 && element?.startDate.split(" ")[0] === elementToCompare?.startDate.split(" ")[0]) {
            addedArray.push(elementToCompare);
          }
          if (i === data?.length - 1) {
            data = data?.filter((da) => da?.startDate?.split(" ")[0] !== element?.startDate?.split(" ")[0]);
            data.unshift(null);
          }
        }
      }
    }
    if (cancelled) {
      setSchedule(reorganizedArray);
      setScheduleAdded(addedArray);
    } else {
      setSchedule(reorganizedArray?.filter((data) => data?.cancelled_at === null));
      setScheduleAdded(addedArray?.filter((data) => data?.cancelled_at === null));
    }
  };
  const getContrastColor = (backgroundColor) => {
    const calculateLuminance = (rgb) => {
      const [r, g, b] = rgb.map((value) => {
        value /= 255;
        return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
      });

      return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    };

    const rgb = hexToRgb(backgroundColor);
    const luminance = calculateLuminance(rgb);

    return luminance > 0.5 ? "black" : "white";
  };
  //get list courts
  useEffect(() => {
    Api.get(Urls.GET_COURTS_LIST, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("courts list:", response.data.data);
        setCourts(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  const ExternalViewSwitcher = ({ currentViewName }) => (
    <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-end">
      <Box display="flex" flexDirection="row">
        <Button onClick={() => setCurrentViewName("Month")} variant={currentViewName === "Month" ? "views_active_left_radius" : "views_inactive_left_radius"}>
          Month
        </Button>
        <Button onClick={() => setCurrentViewName("Week")} variant={currentViewName === "Week" ? "views_active" : "views_inactive"}>
          Week
        </Button>
        <Button onClick={() => setCurrentViewName("Day")} variant={currentViewName === "Day" ? "views_active" : "views_inactive"}>
          Day
        </Button>
      </Box>
    </Box>
  );
  // handlers
  const handleClickAppointment = (data, edit) => {
    setOpenDetails({
      open: true,
      data: data,
    });
    Api.get(Urls?.GET_USER + data?.coach_id, {
      headers: { "content-type": "application", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setCoachData(res?.data?.data);
        setLoadingCoach(false);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        setLoadingCoach(false);
        toast.error(err?.response?.data?.message);
      });
  };

  //get global schedule
  const [schedule, setSchedule] = useState([]);
  useEffect(() => {
    setLoading(true);
    if (term?.id && selectedDate) {
      const date = new Date(selectedDate);

      date.setDate(1);
      const firstDay = new Date(date);

      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
      const lastDay = new Date(date);
      Api.get(Urls?.GET_GLOBAL_SCHEDULE + term?.id + "/courtSchedules", {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          courtId: court?.id,
          ...(sessionType && { sessionType: "PRIVATE_LESSON" }),
          ...(program?.id && !sessionType ? { programId: program?.id } : null),
          startDatetime: new Date(term?.start_date),
          endDatetime: new Date(term?.end_date),
        },
      })
        .then((res) => {
          let data = [];
          for (let index = 0; index < res?.data?.data?.length; index++) {
            const element = res?.data?.data[index];
            data.push({ ...element, startDate: element?.start_datetime, endDate: element?.end_datetime });
          }
          if (currentViewName === "Month") {
            limitOccurenceNumber(data, showCanceledSlots);
          } else {
            if (showCanceledSlots) {
              setSchedule(data);
            } else {
              setSchedule(data?.filter((d) => d?.cancelled_at === null));
            }
          }
          if (id !== null) {
            let element = data?.filter((da) => da?.id === id);
            handleClickAppointment(element[0]);
          }
          const groupedByProgram = res?.data?.data?.reduce((acc, session) => {
            if (!acc[session.program_name]) {
              acc[session.program_name] = {
                program_name: session.program_name ? session.program_name : "PRIVATE SESSION",
                objects: [],
              };
            }
            acc[session.program_name].objects.push(session);

            return acc;
          }, {});
          const resultArray = Object.values(groupedByProgram);
          console.log(resultArray);
          setLoading(false);
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
        });
    }
    setLoading(false);
  }, [program, court, term, change, currentViewName, sessionType, showCanceledSlots]);
  //get list programs
  useEffect(() => {
    console.log(Urls.GET_PROGRAMS, "PROGRAMS");
    Api.get(Urls.GET_PROGRAMS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("programs list:", response.data.data);
        setPrograms(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  useEffect(() => {
    Api.get(Urls.GET_TERMS_LIST, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("terms list:", response.data.data);
        setTerms(response?.data?.data);
        setTerm(response?.data?.data[0]);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  //get players / coourt schedule id
  const [playersCourt, setPlayersCourt] = useState([]);
  const [changePlayer, setChangePlayer] = useState(false);
  useEffect(() => {
    if (openDetails?.data?.id && openDetails?.open) {
      Api.get(Urls?.GET_PLAYERS_COURT_SCHEDULE + openDetails?.data?.id + "/players", {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          setPlayersCourt(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
        });
    }
  }, [openDetails, changePlayer]);

  const CustomAppointmentContent = ({ data, ...restProps }) => {
    // Customize the appointment content based on data properties
    const customStyles = {
      background: data?.new ? "lightgray" : data?.program_color_start && data?.program_color_end ? `linear-gradient(-30deg, #${data?.program_color_start}, #${data?.program_color_end})` : "#1E213E",
      // color: data?.session_type === "PROGRAM" ? data?.color : "white",
      color: data?.new ? "white" : getContrastColor(data?.program_color_start),
      height: data?.new ? "70%" : "100%",
      width: data?.new ? "20px" : currentViewName !== "Week" && currentViewName !== "Day" ? "90%" : "100%",
      boxShadow: "0",
      ":hover": { filter: "brightness(0.95)" },
      filter: data?.new ? "brightness(1)" : data?.cancelled_at ? "brightness(0.6)" : "brightness(1)",
      cursor: "pointer",
    };

    const selectedSession = data?.session_type === "PROGRAM" ? false : true;
    return (
      <>
        {!data?.new && (
          <Box onClick={() => handleClickAppointment(data, selectedSession)} sx={customStyles} display="flex" justifyContent="center" alignItems="center">
            {/* Render appointment content here */}
            {/*{data?.level_name && (
              <Box sx={{ zIndex: "99999" }} padding="2px 5px" backgroundColor="#D0CFCF" color={getContrastColor("#D0CFCF")} position="absolute" top="4px" right="4px" borderRadius="3px">
                <Typography fontSize="13px" fontWeight="600">
                  {data?.level_name}
                </Typography>
              </Box>
            )}*/}
            {data?.levels?.length > 0 && (
                <Box
                    position="absolute"
                    top="4px"
                    right="4px"
                    display="flex"
                    gap="4px" // espace entre les box
                >
                  {data.levels.map((l, index) => {
                    const name = l.name || "";
                    const displayName = name.length > 3 ? name.substring(0, 3) : name;
                    return (
                        <Box
                            key={index}
                            borderRadius="3px"
                            padding="2px 5px"
                            bgcolor="#D0CFCF"
                        >
                          <Typography fontSize="13px" fontWeight="600" color="black">
                            {displayName}
                          </Typography>
                        </Box>
                    );
                  })}
                </Box>
            )}
            <Typography textAlign="center" color="inherit" fontSize="13px" lineHeight="14px" fontWeight="500">
              {data?.program_name ? data?.program_name : "PRIVATE LESSON"}
            </Typography>
          </Box>
        )}
        {data?.new && (
          <Box sx={{ width: "90%" }} onClick={() => handleClickAppointementPlus(data)} display="flex" justifyContent="center" alignItems="center">
            <Box borderRadius="6px" marginTop="4px" sx={customStyles} display="flex" justifyContent="center">
              <MoreHorizIcon sx={{ fontSize: 15 }} />
            </Box>
          </Box>
        )}
      </>
    );
  };
  const handleSubmitNewPlayer = (values) => {
    Api.post(
      Urls.ADD_DROP_IN_PLAYER,
      {
        first_name: values?.first_name,
        last_name: values?.last_name,
        gender: values?.gender.toLowerCase(),
        date_of_birth: values?.date_of_birth,
        court_schedule_id: openDetails?.data?.id,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenDetails({ open: false, data: null });
        setVisible(false);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const handleSubmitexistingPlayer = (values) => {
    Api.post(
      Urls.ADD_DROP_IN_PLAYER,
      {
        player_id: values?.id,
        court_schedule_id: openDetails?.data?.id,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenDetails({ open: false, data: null });
        setChange(!change);
        setVisible(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const debouncedSearch = React.useMemo(() => {
    return debounce((adresse) => {
      searchPlayer(adresse);
    }, 700);
  }, []);
  useEffect(() => {
    debouncedSearch(search);
  }, [search]);
  const changePresencePlayer = (id, presence) => {
    Api.post(
      Urls?.PLAYER_PRESENCE + openDetails?.data?.id + "/players/" + id + "/presence",
      {
        is_present: presence,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setChangePlayer(!changePlayer);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const handleClickCancelSession = () => {
    setOpenCancelSession(true);
  };
  return (
    <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
      <ContentBox>
        <ToastContainer />
        <Box flexWrap="wrap" display="flex" gap="26px">
          <Box flex={1} display="flex" flexDirection="column" gap="12px">
            <Box>
              <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                Filter
              </Typography>
            </Box>
            <Box display="flex" gap="10px" flexDirection="row" alignItems="center">
              <Typography fontSize="20px" fontWeight={600} color={colors.darkgray}>
                PRIVATE LESSONS
              </Typography>
              <MySwitch
                isOn={sessionType}
                onChange={() => {
                  setSessionType(!sessionType);
                }}
              />
            </Box>
            <Box display="flex" gap="10px" flexDirection="row" alignItems="center">
              <Typography fontSize="20px" fontWeight={600} color={colors.darkgray}>
                CANCELLED SLOTS
              </Typography>
              <MySwitch
                isOn={showCanceledSlots}
                onChange={() => {
                  setShowCanceledSlots(!showCanceledSlots);
                }}
              />
            </Box>
            {term && (
              <Box>
                <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                  Select term
                </Typography>
                <Autocomplete
                  fullWidth
                  options={terms}
                  value={term}
                  disableClearable={true}
                  getOptionLabel={(e) => e.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  onChange={(event, value) => {
                    if (value !== null) {
                      //console.log("selecting facture state value", value);
                      //console.log("selecting facture state value", value.id);
                      setTerm(value);
                      //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                    } else {
                      setTerm(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select term" />}
                />
              </Box>
            )}

            {!sessionType && (
              <Box>
                <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                  Program
                </Typography>
                <Autocomplete
                  sx={{ minWidth: "200px" }}
                  options={programs}
                  getOptionLabel={(e) => e.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  onChange={(event, value) => {
                    if (value !== null) {
                      //console.log("selecting facture state value", value);
                      //console.log("selecting facture state value", value.id);
                      setProgram(value);
                      //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                    } else {
                      setProgram(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select program" />}
                />
              </Box>
            )}
            <Box>
              <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                Select court
              </Typography>
              <Autocomplete
                fullWidth
                options={courts}
                value={court}
                getOptionLabel={(e) => e.name}
                isOptionEqualToValue={(option, value) => option?.id === value.id}
                onChange={(event, value) => {
                  if (value !== null) {
                    //console.log("selecting facture state value", value);
                    //console.log("selecting facture state value", value.id);
                    setCourt(value);
                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                  } else {
                    setCourt(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select court" />}
              />
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CalendarPicker
                  date={selectedDate}
                  maxDate={term?.end_date}
                  minDate={term?.start_date}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box flex={4} borderLeft="1px solid lightgray">
            {!loading && (
              <Scheduler data={schedule} height={700}>
                <ViewState onCurrentDateChange={(date) => (new Date(date) < new Date(term?.start_date) ? setSelectedDate(new Date(term?.start_date)) : new Date(term?.start_date) < new Date(date) && new Date(term?.end_date) > new Date(date) ? setSelectedDate(date) : setSelectedDate(new Date(term?.end_date)))} currentDate={selectedDate} currentViewName={currentViewName} />
                <WeekView cellDuration={30} startDayHour={6} endDayHour={22} />
                <MonthView />
                <DayView cellDuration={30} startDayHour={6} endDayHour={22} />
                <Appointments appointmentComponent={CustomAppointmentContent} />
                <Toolbar />
                <Box marginLeft="20px">
                  <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                    TERM : {term?.name}
                  </Typography>
                </Box>
                <DateNavigator onCurrentDateChange={(date) => console.log(date)} />
                <ExternalViewSwitcher currentViewName={currentViewName} />
              </Scheduler>
            )}
            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />{" "}
              </Box>
            )}
          </Box>
        </Box>
      </ContentBox>
      <Modal
        open={openDetails?.open}
        onClose={() =>
          setOpenDetails({
            open: false,
            data: null,
          })
        }
      >
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare
              action="close"
              onClick={() =>
                setOpenDetails({
                  open: false,
                  data: null,
                })
              }
            />
          </Box>
          <Box>
            <Typography fontSize="26px" fontWeight="500" lineHeight="22px" color="#4B465C">
              {moment(openDetails?.data?.start_datetime).format("MMMM DD, YYYY")} {moment(openDetails?.data?.start_datetime).format("hh:mm A")}-{moment(openDetails?.data?.end_datetime).format("hh:mmA ")}
            </Typography>
            <Box marginBottom="24px" marginTop="32px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize="22px" fontWeight="500" lineHeight="18px" color="#4B465C">
                {openDetails?.data?.session_type === "PROGRAM" ? "Program" : "Private lesson"}
              </Typography>
              <Box>
                <Button disabled={openDetails?.data?.cancelled_at ? true : false} onClick={handleClickCancelSession} variant="primary_active">
                  Cancel session
                </Button>
              </Box>
            </Box>
            <Box marginBottom="32px">
              <Typography fontSize="15px" fontWeight="600" lineHeight="22px" color="#4B465C">
                {openDetails?.data?.program_name} {openDetails?.data?.duration}min {openDetails?.data?.session_type === "PROGRAM" && `-max players(${openDetails?.data?.program_max_players})`}
              </Typography>
            </Box>
            <Box>
              <Typography marginBottom="16px" fontSize="22px" fontWeight="500" lineHeight="18px" color="#4B465C">
                Coach
              </Typography>
            </Box>
            <Box marginBottom="23px">
              <TableShowUser url_photo={coachData?.url_avatar} full_name={coachData?.first_name + " " + coachData?.last_name} email={coachData?.email} />
            </Box>
            <Box marginBottom="16px" display="flex" flexDirection="column" gap="16px">
              <Typography fontSize="22px" fontWeight="500" lineHeight="18px" color="#4B465C">
                Players
              </Typography>
              {playersCourt?.map((player) => (
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <TableShowUser isDropIn={player?.is_dropin} url_photo={player?.url_avatar} full_name={player?.first_name + " " + player?.last_name} email={player?.email} />
                  {player?.is_present ? (
                    <Box onClick={() => changePresencePlayer(player?.id, 0)} sx={{ cursor: "pointer" }} padding="5px 15px" backgroundColor="#59E659" borderRadius="16px" border="1px solid #59E659">
                      <Typography color="white">Present</Typography>
                    </Box>
                  ) : (
                    <Box onClick={() => changePresencePlayer(player?.id, 1)} sx={{ cursor: "pointer" }} padding="5px 15px" backgroundColor="#FF6961" borderRadius="16px" border="1px solid #FF6961">
                      <Typography color="white">Absent</Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            <Box>
              <Button onClick={() => setVisible(true)} variant="primary_active">
                {openDetails?.data?.session_type === "PROGRAM" ? "Add drop-in player" : "add partner player"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={visible} onClose={() => setVisible(false)}>
        <Box sx={modalStyleVertical}>
          <Box>
            <Box marginBottom="26px" alignItems="center" display="flex" justifyContent="space-between">
              <Box>
                <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                  Add drop in player
                </Typography>
              </Box>
              <ButtonSquare action="close" onClick={() => setVisible(false)} />
            </Box>
            <Box display="flex" gap="4px" marginBottom="24px">
              <Box>
                <Button onClick={() => setSelectedType("new")} variant={selectedType === "new" ? "primary_active" : "primary_inactive"}>
                  Dropin
                </Button>
              </Box>
              <Box>
                <Button onClick={() => setSelectedType("exist")} variant={selectedType !== "new" ? "primary_active" : "primary_inactive"}>
                  Existing player
                </Button>
              </Box>
            </Box>
            <Box>
              {selectedType === "new" && (
                <Formik initialValues={initialValuesNewPlayer} validationSchema={validationSchemaNewPlayer} onSubmit={(values) => handleSubmitNewPlayer(values)}>
                  {({ errors, values, setFieldValue }) => (
                    <Form>
                      <Box display="flex" flexDirection="column" gap="16px">
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            First name
                          </Typography>
                          <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                          <ErrorMessage component={FormikErrorText} name="first_name" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            Last name
                          </Typography>
                          <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                          <ErrorMessage component={FormikErrorText} name="last_name" />
                        </Box>
                        <Box>
                          <Autocomplete
                            sx={{ minWidth: "100%" }}
                            options={["MALE", "FEMALE"]}
                            getOptionLabel={(e) => e}
                            onChange={(event, value) => {
                              if (value !== null) {
                                setFieldValue("gender", value);
                              } else {
                                setFieldValue("gender", null);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select a gender" />}
                          />
                          <ErrorMessage component={FormikErrorText} name="gender" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            Date of birth{" "}
                          </Typography>
                          <Field fullWidth width="100%" name="date_of_birth" id="date_of_birth" component={BtaDatePicker} disablePast={true} maxDate={new Date()} autoComplete="off" placeholder="Select a date..." />
                          <ErrorMessage component={FormikErrorText} name="date_of_birth" />
                        </Box>
                        <Box marginTop="10px" display="flex" gap="16px">
                          <Button type="submit" variant="primary_active">
                            Add
                          </Button>
                          <Button onClick={() => setVisible(false)} variant="primary_inactive">
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
              {selectedType !== "new" && (
                <Formik initialValues={initialValuesExistantPlayer} validationSchema={validationSchemaExistingPlayer} onSubmit={(values) => handleSubmitexistingPlayer(values)}>
                  {({ errors, values, setFieldValue }) => (
                    <Form>
                      <Box display="flex" flexDirection="column" gap="16px">
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            User name
                          </Typography>
                          <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            onChange={(e, value) => {
                              //console.log(value);
                              if (value) {
                                setFieldValue("user_name", value?.first_name + " ", value?.last_name);
                                setFieldValue("id", value?.id);
                              }
                            }}
                            options={players}
                            getOptionLabel={(Option) => Option.first_name + " " + Option?.last_name}
                            renderInput={(params) => (
                              <TextField
                                sx={{ maxWidth: "100%" }}
                                {...params}
                                variant="outlined"
                                value={values?.user_name}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    setSearch(e.target?.value);
                                  }
                                }}
                                placeholder="user name..."
                              />
                            )}
                          />
                          <ErrorMessage component={FormikErrorText} name="user_name" />
                        </Box>
                        <Box marginTop="10px" display="flex" gap="16px">
                          <Button type="submit" variant="primary_active">
                            Add
                          </Button>
                          <Button onClick={() => setVisible(false)} variant="primary_inactive">
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={addedScheduleToshow?.open} onClose={() => setAddedScheduleToshow({ open: false, data: null })}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setAddedScheduleToshow({ open: false, data: null })} />
          </Box>
          <Box display="flex" justifyContent="center" marginBottom="16px">
            <Box display="flex" flexDirection="column" gap="16px" width="70%">
              {addedScheduleToshow?.data?.map((data) => (
                <Box height="100px" display="flex" justifyContent="center">
                  <CustomAppointmentContent data={data} />
                </Box>
              ))}
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button onClick={() => setAddedScheduleToshow({ open: false, data: null })} variant="primary_inactive">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openCancelSession} onClose={() => setOpenCancelSession(false)}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpenCancelSession(false)} />
          </Box>
          <Box>
            <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
              Would you like to refund this session ?
            </Typography>
          </Box>
          <Box marginTop="16px" justifyContent="center" display="flex" gap="16px">
            <Button onClick={() => handleCancelAppointement(true)} variant="primary_active">
              Refund
            </Button>
            <Button onClick={() => handleCancelAppointement(false)} variant="primary_inactive">
              Do not refund
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default PlayersSchedule;
