import { Autocomplete, Box, Button, Chip, CircularProgress, FormControl, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { DateNavigator, DayView, Toolbar } from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState, EditingState, IntegratedEditing } from "@devexpress/dx-react-scheduler";
import { Scheduler, WeekView, MonthView, Appointments, AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import ContentBox from "../../components/ui/ContentBox";
import { modalStyle, modalStyleOverflow, modalStyleVertical } from "../../theme/ModalStyle";
import ButtonSquare from "../../components/ui/ButtonSquare";
import moment from "moment";
import PersonIcon from "@mui/icons-material/Person";
import { ToastContainer, toast } from "react-toastify";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import * as yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import { hours_time } from "../../components/data/hours";
import FormikErrorText from "../../components/ui/FormikErrorText";
import MySwitch from "../../components/ui/MySwitch/MySwitch";
import { colors } from "../../theme/Colors";
function WeeklySchedule({ id }) {
    const [visible, setVisible] = useState(false);
    const [currentViewName, setCurrentViewName] = useState("Week");
    const [program, setProgram] = useState(null);
    const [programFilter, setProgramFilter] = useState(null);
    const [court, setCourt] = useState();
    const [openCancelSchedule, setOpenCancelSchedule] = useState({ open: false, id: null });
    const perPage = "?perPage=10";
    const [courts, setCourts] = useState([]);
    const [selectedSession, setSelectedSession] = useState("normal");
    const [selectedSessionUpdate, setSelectedSessionUpdate] = useState("");
    const [coachFil, setCoachFil] = useState();
    const [edit, setEdit] = useState(false);
    const [sessionType, setSessionType] = useState(false);
    const [date, setDate] = useState(null);
    const [dateUpdate, setDateUpdate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataCourts, setDataCourts] = useState([]);
    const [showCanceledSlots, setShowCanceledSlots] = useState(false);
    const { user } = useAuthContext();
    const [change, setChange] = useState(false);
    const [loadingCancel, setLoadingCancel] = useState(false);
    const access_token = user?.access_token;
    const [terms, setTerms] = useState([]);
    const [term, setTerm] = useState();
    const [startTime, setStartTime] = useState("");
    const [duration, setDuration] = useState("");

    const [openDetails, setOpenDetails] = useState({
        open: false,
        data: null,
    });
    const [durationsAdd, setDurationsAdd] = useState({ loading: false, data: null });
    const [dataT, setData] = useState({});
    const [initialValuesNorml, setInitialValuesNorml] = useState({
        program: null,
        levels: [],
        start_time: "",
        duration: "",
        coach: "",
    });
    const [initialValuesPrv, setInitialValuesPrv] = useState({
        program: "",
        levels: [],
        start_time: "",
        duration: "",
        coach: "",
    });
    const validationSchema = yup.object({
        program: yup?.object().shape({
            id: yup?.string().required("This field is required"),
        }),
        levels: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.string().required("Level id is required"),
                })
            )
            .min(1, "Select at least one level")
            .required("Level(s) is required"),
        start_time: yup?.string().required("this field is required"),
        duration: yup?.object().shape({
            duration: yup?.string().required("This field is required"),
        }),
        coach: yup?.string().required("this field is required"),
    });
    const validationSchemaPrivate = yup.object({
        start_time: yup?.string().required("this field is required"),
        duration: yup?.object().shape({
            duration: yup?.string().required("This field is required"),
        }),
        coach: yup?.string().required("this field is required"),
    });
    useEffect(() => {
        document.title = "BTA admin - Weekly schedule";
    }, []);
    const ExternalViewSwitcher = ({ currentViewName }) => (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box marginLeft="20px">
                <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                    TERM : {term?.name} / {court?.name} {term?.published_at ? <Chip label="Published" variant="green" /> : <Chip label="Not published" variant="red" />}
                </Typography>
            </Box>
        </Box>
    );
    const [schedule, setSchedule] = useState([]);

    // function to preprocess schedule data
    function preprocessData(sessionData, canceled) {
        const dayMapping = {
            sunday: 0,
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6,
        };

        const currentDateTime = new Date();
        const currentDay = currentDateTime.getDay();

        // Calculate the date of the previous Sunday
        const previousSundayDate = new Date(currentDateTime);
        previousSundayDate.setDate(currentDateTime.getDate() - currentDay);

        const processedData = sessionData.map((session) => {
            // Calculate the day difference using the dayMapping
            const dayDifference = (dayMapping[session.day_name.toLowerCase()] - dayMapping["sunday"] + 7) % 7;

            // Calculate the next occurrence using the previous Sunday as a reference
            const nextOccurrence = new Date(previousSundayDate);
            nextOccurrence.setDate(previousSundayDate.getDate() + dayDifference);

            const startTimeParts = session.start_time.split(":");
            const endTimeParts = session.end_time.split(":");

            const startDate = new Date(nextOccurrence.getFullYear(), nextOccurrence.getMonth(), nextOccurrence.getDate(), parseInt(startTimeParts[0], 10), parseInt(startTimeParts[1], 10), parseInt(startTimeParts[2], 10));

            const endDate = new Date(nextOccurrence.getFullYear(), nextOccurrence.getMonth(), nextOccurrence.getDate(), parseInt(endTimeParts[0], 10), parseInt(endTimeParts[1], 10), parseInt(endTimeParts[2], 10));

            // Add start_date and end_date to the session
            session.startDate = startDate;
            session.endDate = endDate;

            return session;
        });
        if (canceled) {
            setSchedule(processedData ? processedData : []);
        } else {
            const filtered_data = processedData?.filter((data) => data?.cancelled_at === null);
            setSchedule(filtered_data);
        }
        console.log(processedData, "schedule");
    }
    //get weekly schedule
    useEffect(() => {
        if (term) {
            Api.get(Urls?.GET_WEEKLY_SCHEDULE + term?.id + "/courtWeeklySchedules", {
                headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
                params: {
                    courtId: court?.id,
                    ...(programFilter && !sessionType && { programId: programFilter?.id }),
                    ...(coachFil ? { coachId: coachFil?.id } : null),
                    ...(sessionType && { sessionType: "PRIVATE_LESSON" }),
                },
            })
                .then((res) => {
                    preprocessData(res?.data?.data, showCanceledSlots);
                    setLoading(false);
                })
                .catch((err) => {
                    toast?.error(err?.response?.data?.message);
                    setLoading(false);
                });
        }
        setLoading(false);
    }, [term, change, court, coachFil, programFilter, sessionType, showCanceledSlots]);
    // handlers
    const handleClickAppointment = (data, edit) => {
        setEdit(edit);
        setOpenDetails({
            open: true,
            data: data,
        });
        if (data?.session_type === "PROGRAM") {
            let start_time = moment(data?.start_time, "HH:mm:ss").format("hh:mm A");

            setDuration(data?.duration);
            setStartTime(start_time);
            setDateUpdate(data?.day_name);
            Api.get(Urls.GET_PROGRAMS + "/" + data?.program_id, {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
            })
                .then((res) => {
                    console.log(data, "ex");

                    setDurations(res?.data?.data?.program_durations);
                    setLevels(res?.data?.data?.levels);
                    console.log(start_time, "start time");
                    setSelectedSessionUpdate(data?.session_type === "PROGRAM" ? "normal" : "private");
                    setInitialValuesNorml({
                        program: { id: data?.program_id, name: data?.program_name },
                        start_time: start_time.toString(),
                        duration: { id: null, duration: data?.duration },
                        coach: data?.coach_id,
                        coach_first_name: data?.coach_first_name,
                        coach_last_name: data?.coach_last_name,
                        levels:
                            data?.levels && data?.levels.length > 0
                                ? data.levels
                                : [{ id: data?.level_id, name: data?.level_name }],
                        cancelled_at: data?.cancelled_at,
                    });
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
        if (data?.session_type === "PRIVATE_LESSON") {
            let start_time = moment(data?.start_time, "HH:mm:ss").format("hh:mm A");

            setDuration(data?.duration);
            setStartTime(start_time);
            setDateUpdate(data?.day_name);
            Api.get(Urls.GET_PRIVATE_LESSONS, {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
            })
                .then((res) => {
                    setDurations(res?.data?.data);
                    setSelectedSessionUpdate("private");
                    setInitialValuesPrv({
                        start_time: start_time.toString(),
                        duration: { id: null, duration: data?.duration },
                        coach: data?.coach_id,
                        coach_first_name: data?.coach_first_name,
                        coach_last_name: data?.coach_last_name,
                    });
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    };
    const handleCloseDetails = () => {
        setVisible(false);
        setInitialValuesNorml({
            program: null,
            levels: [],
            start_time: "",
            duration: "",
            coach: "",
        });
        setDuration(null);
        setProgram(null);
        setCoachs([]);
        setDate(null);
        setDurations([]);
        setDurationsAdd({ loading: true, data: [] });
    };
    const handleCloseUpdate = () => {
        setOpenDetails({
            open: false,
            data: null,
        });
        setInitialValuesNorml({
            program: program,
            levels: [],
            start_time: "",
            duration: "",
            coach: "",
        });
        setDateUpdate(null);
        setProgram(null);
    };
    //get list courts
    useEffect(() => {
        Api.get(Urls.GET_COURTS_LIST, {
            headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        })
            .then((response) => {
                console.log("courts list:", response.data.data);
                setCourts(response?.data?.data);
                setDataCourts(response?.data);
                setCourt(response?.data?.data[0]);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    }, []);
    const [dataProg, setDataProg] = useState([]);
    const [programs, setPrograms] = useState([]);
    //get list programs
    useEffect(() => {
        console.log(Urls.GET_PROGRAMS, "PROGRAMS");
        Api.get(Urls.GET_PROGRAMS, {
            headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        })
            .then((response) => {
                console.log("programs list:", response.data.data);
                setPrograms(response?.data?.data);
                setDataProg(response?.data);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    }, []);
    //get list terms
    useEffect(() => {
        Api.get(Urls.GET_TERMS_LIST, {
            headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        })
            .then((response) => {
                console.log("terms list:", response.data.data);
                setTerms(response?.data?.data);
                setData(response?.data);
                setTerm(response?.data?.data[0]);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    }, []);
    const [durations, setDurations] = useState([]);
    const [levels, setLevels] = useState([]);

    /*useEffect(() => {
        Api.get(Urls.GET_LEVELS, {
            headers: {
                "content-type": "application/json",
                authorization: `Bearer ${access_token}`,
            },
        })
            .then((res) => {
                // res.data.data (selon la structure de votre API)
                setLevels(res?.data?.data || []);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Erreur lors de la récupération des levels");
            });
    }, [access_token]);*/

    useEffect(() => {
        setDurationsAdd({ loading: true, data: [] });
        if (program && selectedSession === "normal") {
            setInitialValuesNorml({
                levels: [],
                program: program,
                coach: "",
                duration: "",
                start_time: moment(date?.startDate).format("hh:00 A"),
            });
            setStartTime(moment(date?.startDate).format("hh:00 A"));
            //get program durations
            Api.get(Urls.GET_PROGRAMS + "/" + program?.id, {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
            })
                .then((res) => {
                    console.log("executed");

                    setDurationsAdd(() => {
                        return { loading: false, data: res?.data?.data?.program_durations };
                    });
                    setLevels(res?.data?.data?.levels);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
        if (selectedSession !== "normal") {
            setInitialValuesPrv({
                levels: [],
                program: program,
                coach: "",
                duration: "",
                start_time: moment(date?.startDate).format("hh:00 A"),
            });
            setStartTime(moment(date?.startDate).format("hh:00 A"));
            Api.get(Urls.GET_PRIVATE_LESSONS, {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
            })
                .then((res) => {
                    setDurations(res?.data?.data);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    }, [program, selectedSession]);

    useEffect(() => {
        //get program durations
        if (program?.id) {
            Api.get(Urls.GET_PROGRAMS + "/" + program?.id, {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
            })
                .then((res) => {
                    setDurations(res?.data?.data?.program_durations);
                    setLevels(res?.data?.data?.levels);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    }, [program]);

    useEffect(() => {
        if (date) {
            if (selectedSession === "normal") {
                setInitialValuesNorml({ ...initialValuesNorml, start_time: moment(date?.startDate).format("hh:00 A") });
            } else {
                setInitialValuesPrv({ ...initialValuesPrv, start_time: moment(date?.startDate).format("hh:00 A") });
            }

            setStartTime(moment(date?.startDate).format("hh:00 A"));
        }
    }, [date]);
    //get available coach
    const [coachs, setCoachs] = useState([]);
    useEffect(() => {
        if (term && startTime && duration && date) {
            let startTime_ = moment(startTime, "hh:mm A");
            startTime_ = moment(startTime_, "HH:mm");
            let endTime = startTime_?.clone().add(duration?.duration, "minutes");
            endTime = endTime?.format("HH:mm");
            startTime_ = startTime_.format("HH:mm");

            Api.get(Urls?.GET_AVAILABLE_COACHS, {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
                params: {
                    termId: term?.id,
                    dayName: moment(date?.startDate).format("dddd").toLowerCase(),
                    startTime: startTime_ + ":00",
                    endTime: endTime?.split(" ")[0] + ":00",
                },
            })
                .then((res) => {
                    console.log(res);
                    setCoachs(() => res?.data?.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [term, duration, startTime, date]);
    useEffect(() => {
        if (term && startTime && duration && dateUpdate) {
            let startTime_ = moment(startTime, "hh:mm A");
            startTime_ = moment(startTime_, "HH:mm");
            let endTime = startTime_?.clone().add(duration?.duration ? duration?.duration : duration, "minutes");
            endTime = endTime?.format("HH:mm");
            startTime_ = startTime_.format("HH:mm");
            if (startTime_ + ":00" !== endTime?.split(" ")[0] + ":00") {
                console.log(startTime_ + ":00" === endTime?.split(" ")[0] + ":00");
                Api.get(Urls?.GET_AVAILABLE_COACHS, {
                    headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
                    params: {
                        termId: term?.id,
                        dayName: dateUpdate,
                        startTime: startTime_ + ":00",
                        endTime: endTime?.split(" ")[0] + ":00",
                    },
                })
                    .then((res) => {
                        console.log(res);
                        setCoachs(res?.data?.data);
                    })
                    .catch((err) => {
                        toast?.error(err?.response?.data?.message);
                    });
            }
        }
    }, [term, duration, startTime, dateUpdate]);

    const coach = {
        id: 1,
        name: "Yelena O'Hear",
        url_avatar: null,
    };
    const CustomAppointmentContent = ({ data, ...restProps }) => {
        // Customize the appointment content based on data properties
        const customStyles = {
            background: data?.program_color_start && data?.program_color_end ? `linear-gradient(-30deg, #${data?.program_color_start}, #${data?.program_color_end})` : "#1E213E",
            // color: data?.session_type === "PROGRAM" ? data?.color : "white", // Default text color
            color: getContrastColor(data?.program_color_start),
            height: "100%",
            filter: data?.new ? "brightness(1)" : data?.cancelled_at ? "brightness(0.6)" : "brightness(1)",
            width: "100%",
            boxShadow: "0",
            ":hover": { filter: "brightness(0.95)" },
            paddingTop: "2rem",
        };
        let termPublished = term?.published_at ? false : true;
        return (
            <Box
                sx={customStyles}
                onClick={() => handleClickAppointment(data, termPublished)}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                {data?.levels?.length > 0 && (
                    <Box
                        position="absolute"
                        top="4px"
                        right="4px"
                        display="flex"
                        gap="4px" // espace entre les box
                    >
                        {data.levels.map((l, index) => {
                            const name = l.name || "";
                            const displayName = name.length > 3 ? name.substring(0, 3) : name;
                            return (
                                <Box
                                    key={index}
                                    borderRadius="3px"
                                    padding="2px 5px"
                                    bgcolor="#D0CFCF"
                                >
                                    <Typography fontSize="13px" fontWeight="600" color="black">
                                        {displayName}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>
                )}


                {/* Avec le padding-top, le programme ne sera pas recouvert */}
                <Typography
                    textAlign="center"
                    color="inherit"
                    fontSize="13px"
                    lineHeight="14px"
                    fontWeight="500"
                >
                    {data?.program_name ? data?.program_name : "PRIVATE LESSON"}
                </Typography>
            </Box>
        );
    };
    //get coachs filter
    const [users, setUsers] = useState([]);
    useEffect(() => {
        Api.get(Urls?.GET_COACHES, {
            headers: { "content-type": "application/json", Authorization: `Bearer ${access_token}` },
        })
            .then((res) => {
                setUsers(res?.data?.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            });
    }, []);
    const submitCreateNewCourt = (values) => {
        let startTime_ = moment(values?.start_time, "hh:mm A");
        startTime_ = moment(startTime_, "HH:mm");
        startTime_ = startTime_.format("HH:mm");

        Api.post(
            Urls.CREATE_NORMAL_SESSION,
            {
                court_id: court?.id,
                term_id: term?.id,
                coach_id: values?.coach,
                //level_id: values?.level?.id,
                level_ids: values.levels.map((lv) => lv.id),
                program_duration_id: values?.duration?.id,
                day_name: moment(date?.startDate).format("dddd").toLowerCase(),
                start_time: startTime_ + ":00",
            },
            {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
            }
        )
            .then((res) => {
                toast?.success("added");
                setVisible(false);
                setChange(!change);
                handleCloseDetails();
            })
            .catch((err) => {
                toast?.error(err?.response?.data?.message);
            });
    };
    const submitCreateNewCourtPriate = (values) => {
        let startTime_ = moment(values?.start_time, "hh:mm A");
        startTime_ = moment(startTime_, "HH:mm");
        startTime_ = startTime_.format("HH:mm");

        Api.post(
            Urls.CREATE_NORMAL_SESSION,
            {
                court_id: court?.id,
                term_id: term?.id,
                coach_id: values?.coach,
                private_lesson_id: values?.duration?.id,
                day_name: moment(date?.startDate).format("dddd").toLowerCase(),
                start_time: startTime_ + ":00",
            },
            {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
            }
        )
            .then((res) => {
                toast?.success("added");
                setVisible(false);
                setChange(!change);
            })
            .catch((err) => {
                toast?.error(err?.response?.data?.message);
            });
    };
    const submitUpdateCourt = (values) => {
        let startTime_ = moment(values?.start_time, "hh:mm A");
        startTime_ = moment(startTime_, "HH:mm");
        startTime_ = startTime_.format("HH:mm");

        Api.patch(
            Urls.UPDATE_COURT_WEEKLY_SCHEDULE + openDetails?.data?.id,
            {
                court_weekly_schedules_id: openDetails?.data?.id,
                court_id: court?.id,
                term_id: term?.id,
                coach_id: values?.coach,
                level_ids: values.levels.map((lv) => lv.id),
                ...(values?.duration?.id && { program_duration_id: values?.duration?.id }),
                start_time: startTime_ + ":00",
            },
            {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
            }
        )
            .then((res) => {
                toast?.success("updated");
                handleCloseUpdate();
                setChange(!change);
            })
            .catch((err) => {
                toast?.error(err?.response?.data?.message);
            });
    };
    const submitUpdatePrivateCourt = (values) => {
        let startTime_ = moment(values?.start_time, "hh:mm A");
        startTime_ = moment(startTime_, "HH:mm");
        startTime_ = startTime_.format("HH:mm");

        Api.patch(
            Urls.UPDATE_COURT_WEEKLY_SCHEDULE + openDetails?.data?.id,
            {
                court_id: court?.id,
                term_id: term?.id,
                coach_id: values?.coach,
                ...(values?.duration?.id ? { private_lesson_id: values?.duration?.id } : null),
                start_time: startTime_ + ":00",
            },
            {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
            }
        )
            .then((res) => {
                toast?.success("updated");
                setVisible(false);
                handleCloseUpdate();
                setChange(!change);
            })
            .catch((err) => {
                toast?.error(err?.response?.data?.message);
            });
    };
    const submitDeleteSchedule = () => {
        Api.delete(Urls.DELETE_COURT_WEEKLY_SCHEDULE + openDetails?.data?.id, {
            headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        })
            .then((res) => {
                toast?.success("Deleted");
                handleCloseUpdate();
                setChange(!change);
            })
            .catch((err) => {
                toast?.error(err?.response?.data?.message);
            });
    };

    //background color & text color management for schedules
    const hexToRgb = (hex) => {
        const bigint = parseInt(hex?.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    };

    const getContrastColor = (backgroundColor) => {
        const calculateLuminance = (rgb) => {
            const [r, g, b] = rgb.map((value) => {
                value /= 255;
                return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
            });

            return 0.2126 * r + 0.7152 * g + 0.0722 * b;
        };

        const rgb = hexToRgb(backgroundColor);
        const luminance = calculateLuminance(rgb);

        return luminance > 0.5 ? "black" : "white";
    };
    const handleCancelShedule = (bool) => {
        setLoadingCancel(true);
        Api.post(
            Urls?.CANCEL_WEEKLY_SCHEDULE + openCancelSchedule?.id + "/cancel",
            {
                refund: bool ? 1 : 0,
            },
            {
                headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
            }
        )
            .then((res) => {
                setOpenCancelSchedule({ open: false, id: null });
                setOpenDetails({ data: null, open: false });
                toast.success(res?.data?.message);
                setLoadingCancel(false);

                setChange(!change);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
                setLoadingCancel(false);
            });
    };
    return (
        <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
            <ToastContainer />
            <Box>
                <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
                    Weekly schedule
                </Typography>
            </Box>
            {court && term && (
                <ContentBox>
                    <Box width="100%" display="flex" gap="16px">
                        <Box flex={1}>
                            <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                                Select term
                            </Typography>
                            <Autocomplete
                                fullWidth
                                options={terms}
                                value={term}
                                disableClearable={true}
                                getOptionLabel={(e) => e.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                onChange={(event, value) => {
                                    if (value !== null) {
                                        //console.log("selecting facture state value", value);
                                        //console.log("selecting facture state value", value.id);
                                        setTerm(value);
                                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                    } else {
                                        setTerm(null);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select term" />}
                            />
                        </Box>
                        <Box flex={1}>
                            <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                                Select court
                            </Typography>
                            <Autocomplete
                                fullWidth
                                options={courts}
                                value={court}
                                disableClearable={true}
                                getOptionLabel={(e) => e.name}
                                isOptionEqualToValue={(option, value) => option?.id === value.id}
                                onChange={(event, value) => {
                                    if (value !== null) {
                                        //console.log("selecting facture state value", value);
                                        //console.log("selecting facture state value", value.id);
                                        setCourt(value);
                                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                    } else {
                                        setCourt(null);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select court" />}
                            />
                        </Box>
                    </Box>
                </ContentBox>
            )}
            <ContentBox>
                <Box sx={{ overflowX: "scroll", flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" } }} display="flex" gap="26px">
                    <Box flex={1} display="flex" flexDirection="column" gap="12px">
                        <Box>
                            <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                                Filter
                            </Typography>
                        </Box>
                        <Box display="flex" gap="10px" flexDirection="row" alignItems="center">
                            <Typography fontSize="20px" fontWeight={600} color={colors.darkgray}>
                                PRIVATE LESSONS
                            </Typography>
                            <MySwitch
                                isOn={sessionType}
                                onChange={() => {
                                    setSessionType(!sessionType);
                                }}
                            />
                        </Box>
                        <Box display="flex" gap="10px" flexDirection="row" alignItems="center">
                            <Typography fontSize="20px" fontWeight={600} color={colors.darkgray}>
                                CANCELLED SLOTS
                            </Typography>
                            <MySwitch
                                isOn={showCanceledSlots}
                                onChange={() => {
                                    setShowCanceledSlots(!showCanceledSlots);
                                }}
                            />
                        </Box>
                        {!sessionType && (
                            <Box>
                                <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                                    Program
                                </Typography>
                                <Autocomplete
                                    sx={{ minWidth: "200px" }}
                                    options={programs}
                                    value={programFilter}
                                    disableClearable={true}
                                    getOptionLabel={(e) => e.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value.id}
                                    onChange={(event, value) => {
                                        if (value !== null) {
                                            //console.log("selecting facture state value", value);
                                            //console.log("selecting facture state value", value.id);
                                            setProgramFilter(value);
                                            //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                        } else {
                                            setProgramFilter(null);
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select type user" />}
                                />
                            </Box>
                        )}
                        <Box>
                            <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                                Coach
                            </Typography>
                            <Autocomplete
                                sx={{ minWidth: "200px" }}
                                options={users}
                                getOptionLabel={(e) => e.first_name + " " + e.last_name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                onChange={(event, value) => {
                                    if (value !== null) {
                                        //console.log("selecting facture state value", value);
                                        //console.log("selecting facture state value", value.id);
                                        setCoachFil(value);
                                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                    } else {
                                        setCoachFil(null);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select coach" />}
                            />
                        </Box>
                    </Box>

                    <Box flex={4} borderLeft="1px solid lightgray">
                        {schedule && !loading && (
                            <Scheduler data={schedule} height={800}>
                                <ViewState onCurrentDateChange={(date) => console.log(date)} defaultCurrentDate={new Date()} currentViewName={currentViewName} />
                                <WeekView
                                    dayScaleRowComponent={() => {
                                        return (
                                            <Box height="70px" justifyContent="center" alignItems="center" display="flex" width="100%">
                                                <Typography sx={{ fontSize: { xs: "9px", sm: "13px", md: "15px", lg: "15px" } }} textAlign="center" flex={1}>
                                                    SUN
                                                </Typography>
                                                <Typography sx={{ fontSize: { xs: "9px", sm: "13px", md: "15px", lg: "15px" } }} textAlign="center" flex={1}>
                                                    MON
                                                </Typography>
                                                <Typography sx={{ fontSize: { xs: "9px", sm: "13px", md: "15px", lg: "15px" } }} textAlign="center" flex={1}>
                                                    TUE
                                                </Typography>
                                                <Typography sx={{ fontSize: { xs: "9px", sm: "13px", md: "15px", lg: "15px" } }} textAlign="center" flex={1}>
                                                    WED
                                                </Typography>
                                                <Typography sx={{ fontSize: { xs: "9px", sm: "13px", md: "15px", lg: "15px" } }} textAlign="center" flex={1}>
                                                    THU
                                                </Typography>
                                                <Typography sx={{ fontSize: { xs: "9px", sm: "13px", md: "15px", lg: "15px" } }} textAlign="center" flex={1}>
                                                    FRI
                                                </Typography>
                                                <Typography sx={{ fontSize: { xs: "9px", sm: "13px", md: "15px", lg: "15px" } }} textAlign="center" flex={1}>
                                                    SAT
                                                </Typography>
                                            </Box>
                                        );
                                    }}
                                    cellDuration={30}
                                    startDayHour={6}
                                    endDayHour={22}
                                />
                                <Appointments appointmentComponent={CustomAppointmentContent} />
                                <Toolbar />
                                <ExternalViewSwitcher currentViewName={currentViewName} />
                                <EditingState />
                                <AppointmentForm
                                    visible={visible}
                                    onAppointmentDataChange={(data) => {
                                        setDate(data);
                                    }}
                                    onVisibilityChange={(e) => {
                                        setVisible(e);
                                        setInitialValuesNorml({
                                            program: null,
                                            levels: [],
                                            start_time: "",
                                            duration: "",
                                            coach: "",
                                        });
                                        setCoachs([]);
                                        setDuration(null);
                                        setDurations([]);
                                        setDurationsAdd({ loading: true, data: [] });
                                    }}
                                    overlayComponent={() => {
                                        return null;
                                    }}
                                />
                            </Scheduler>
                        )}
                        {loading && (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        )}
                    </Box>

                    <Modal
                        open={visible}
                        onClose={() => {
                            setVisible(false);
                            setInitialValuesNorml({
                                program: null,
                                levels: [],
                                start_time: "",
                                duration: "",
                                coach: "",
                            });
                            setDate(null);
                            setDuration(null);
                            setProgram(null);
                            setCoachs([]);
                            setDurations([]);
                            setDurationsAdd({ loading: true, data: [] });
                        }}
                    >
                        <Box sx={modalStyleVertical}>
                            <Box>
                                <Box marginBottom="26px" alignItems="center" display="flex" justifyContent="space-between">
                                    <Box>
                                        <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                                            Schedule session for {moment(date?.startDate).format("dddd")}
                                        </Typography>
                                    </Box>
                                    <ButtonSquare action="close" onClick={handleCloseDetails} />
                                </Box>
                                <Box display="flex" gap="4px" marginBottom="24px">
                                    <Box>
                                        <Button
                                            onClick={() => {
                                                setCoachs([]);
                                                setSelectedSession("normal");
                                            }}
                                            variant={selectedSession === "normal" ? "primary_active" : "primary_inactive"}
                                        >
                                            Schedule a session
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            onClick={() => {
                                                setCoachs([]);

                                                setSelectedSession("private");
                                            }}
                                            variant={selectedSession !== "normal" ? "primary_active" : "primary_inactive"}
                                        >
                                            Schedule a private session
                                        </Button>
                                    </Box>
                                </Box>
                                {selectedSession === "normal" && (
                                    <Formik onSubmit={(values) => submitCreateNewCourt(values)} validationSchema={validationSchema} initialValues={initialValuesNorml} enableReinitialize={true}>
                                        {({ errors, setFieldValue, values }) => (
                                            <Form>
                                                <Box display="flex" flexDirection="column" gap="16px">
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Program
                                                        </Typography>
                                                        <Autocomplete
                                                            sx={{ minWidth: "300px" }}
                                                            options={programs}
                                                            value={values?.program}
                                                            getOptionLabel={(e) => e.name}
                                                            isOptionEqualToValue={(option, value) => option?.id === value.id}
                                                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select program" />}
                                                            onChange={(event, value) => {
                                                                if (value !== null) {
                                                                    //console.log("selecting facture state value", value);
                                                                    //console.log("selecting facture state value", value.id);
                                                                    setFieldValue("program", value);
                                                                    setProgram(value);
                                                                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                                                } else {
                                                                    setFieldValue("program", null);
                                                                    setProgram(null);
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage component={FormikErrorText} name="program" />
                                                    </Box>

                                                    <Box mb={2}>
                                                        <Typography fontSize="13px" fontWeight="400">Levels</Typography>
                                                        <Autocomplete
                                                            multiple
                                                            id="autocomplete-levels"
                                                            options={levels}
                                                            value={values.levels || []}
                                                            onChange={(event, newValue) => {
                                                                setFieldValue("levels", newValue);
                                                            }}
                                                            getOptionLabel={(option) => option.name || ""}
                                                            filterSelectedOptions
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name="autocomplete"
                                                                    placeholder="Select level(s)"
                                                                />
                                                            )}
                                                        />
                                                        {/* Message d'erreur Formik */}
                                                        <ErrorMessage component={FormikErrorText} name="levels" />
                                                    </Box>
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Start time
                                                        </Typography>
                                                        <Autocomplete
                                                            onChange={(event, value) => {
                                                                if (value !== null) {
                                                                    //console.log("selecting facture state value", value);
                                                                    //console.log("selecting facture state value", value.id);
                                                                    setFieldValue("start_time", value);
                                                                    setStartTime(value);
                                                                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                                                } else {
                                                                    setFieldValue("start_time", null);
                                                                    setStartTime(null);
                                                                }
                                                            }}
                                                            sx={{ minWidth: "300px" }}
                                                            options={hours_time}
                                                            value={values?.start_time}
                                                            getOptionLabel={(e) => e}
                                                            isOptionEqualToValue={(option, value) => option === value}
                                                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select start time" />}
                                                        />
                                                        <ErrorMessage component={FormikErrorText} name="start_time" />
                                                    </Box>
                                                    {!durationsAdd?.loading && (
                                                        <Box>
                                                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                                Duration
                                                            </Typography>
                                                            <Autocomplete
                                                                sx={{ minWidth: "300px" }}
                                                                options={durationsAdd.data}
                                                                value={values?.duration ? values?.duration : null}
                                                                getOptionLabel={(e) => e?.duration?.toString()}
                                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                                                                onChange={(event, value) => {
                                                                    if (value !== null) {
                                                                        setFieldValue("duration", value);
                                                                        setDuration(value);
                                                                    } else {
                                                                        setFieldValue("duration", null);
                                                                        setDuration(null);
                                                                    }
                                                                }}
                                                            />
                                                            <ErrorMessage component={FormikErrorText} name="duration" />
                                                        </Box>
                                                    )}
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Coach
                                                        </Typography>
                                                        <FormControl sx={{ height: "42px" }} fullWidth>
                                                            <Select
                                                                onChange={(e) => {
                                                                    setFieldValue("coach", e.target?.value);
                                                                    console.log(e.target?.value, "e log");
                                                                }}
                                                                value={values?.coach}
                                                                placeholder="Select coach"
                                                                sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                            >
                                                                {coachs?.map((coach) => (
                                                                    <MenuItem key={coach?.id} value={coach?.id}>
                                                                        <Box display="flex" alignItems="center" gap="5px">
                                                                            <Box marginTop="5px">{coach?.url_avatar ? <img src={coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                                                            <Box>{coach?.first_name + " " + coach?.last_name}</Box>
                                                                        </Box>
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <ErrorMessage component={FormikErrorText} name="coach" />
                                                    </Box>
                                                    <Box display="flex" gap="16px">
                                                        <Button type="submit" variant="primary_active">
                                                            Add
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setVisible(false);
                                                                setInitialValuesNorml({
                                                                    program: null,
                                                                    levels: [],
                                                                    start_time: "",
                                                                    duration: "",
                                                                    coach: "",
                                                                });
                                                                setDuration(null);
                                                                setProgram(null);
                                                                setCoachs([]);
                                                                setDurations([]);
                                                                setDurationsAdd({ loading: true, data: [] });
                                                            }}
                                                            type="submit"
                                                            variant="primary_inactive"
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                                {selectedSession !== "normal" && (
                                    <Formik onSubmit={(values) => submitCreateNewCourtPriate(values)} validationSchema={validationSchemaPrivate} initialValues={initialValuesPrv} enableReinitialize={true}>
                                        {({ errors, setFieldValue, values }) => (
                                            <Form>
                                                <Box display="flex" flexDirection="column" gap="16px">
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Start time
                                                        </Typography>
                                                        <Autocomplete
                                                            onChange={(event, value) => {
                                                                if (value !== null) {
                                                                    //console.log("selecting facture state value", value);
                                                                    //console.log("selecting facture state value", value.id);
                                                                    setFieldValue("start_time", value);
                                                                    setStartTime(value);
                                                                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                                                } else {
                                                                    setFieldValue("start_time", null);
                                                                    setStartTime(null);
                                                                }
                                                            }}
                                                            sx={{ minWidth: "300px" }}
                                                            options={hours_time}
                                                            value={values?.start_time}
                                                            getOptionLabel={(e) => e}
                                                            isOptionEqualToValue={(option, value) => option === value}
                                                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select start time" />}
                                                        />
                                                        <ErrorMessage component={FormikErrorText} name="start_time" />
                                                    </Box>
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Duration
                                                        </Typography>
                                                        <Autocomplete
                                                            sx={{ minWidth: "300px" }}
                                                            options={durations}
                                                            value={values?.duration ? values?.duration : null}
                                                            getOptionLabel={(e) => e?.duration?.toString()}
                                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                                                            onChange={(event, value) => {
                                                                if (value !== null) {
                                                                    setFieldValue("duration", value);
                                                                    setDuration(value);
                                                                } else {
                                                                    setFieldValue("duration", null);
                                                                    setDuration(null);
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage component={FormikErrorText} name="duration" />
                                                    </Box>
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Coach
                                                        </Typography>
                                                        <FormControl sx={{ height: "42px" }} fullWidth>
                                                            <Select
                                                                onChange={(e) => {
                                                                    setFieldValue("coach", e.target?.value);
                                                                    console.log(e.target?.value, "e log");
                                                                }}
                                                                value={values?.coach}
                                                                placeholder="Select coach"
                                                                sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                            >
                                                                {coachs?.map((coach) => (
                                                                    <MenuItem key={coach?.id} value={coach?.id}>
                                                                        <Box display="flex" alignItems="center" gap="5px">
                                                                            <Box marginTop="5px">{coach?.url_avatar ? <img src={coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                                                            <Box>{coach?.first_name + " " + coach?.last_name}</Box>
                                                                        </Box>
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <ErrorMessage component={FormikErrorText} name="coach" />
                                                    </Box>

                                                    <Box display="flex" gap="16px">
                                                        <Button type="submit" variant="primary_active">
                                                            Add
                                                        </Button>
                                                        <Button onClick={() => setVisible(false)} type="submit" variant="primary_inactive">
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </Box>
                        </Box>
                    </Modal>
                    <Modal open={openDetails?.open} onClose={handleCloseUpdate}>
                        <Box sx={modalStyleVertical}>
                            <Box>
                                <Box marginBottom="26px" alignItems="center" display="flex" justifyContent="space-between">
                                    <Box>
                                        <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                                            Schedule session for {openDetails?.data?.day_name}
                                        </Typography>
                                    </Box>
                                    <ButtonSquare action="close" onClick={handleCloseUpdate} />
                                </Box>
                                <Box display="flex" gap="4px" marginBottom="24px">
                                    <Box>
                                        <Button variant={selectedSessionUpdate === "normal" ? "primary_active" : "primary_inactive"}>Schedule a session</Button>
                                    </Box>
                                    <Box>
                                        <Button variant={selectedSessionUpdate !== "normal" ? "primary_active" : "primary_inactive"}>Schedule a private session</Button>
                                    </Box>
                                </Box>
                                {selectedSessionUpdate === "normal" && (
                                    <Formik onSubmit={(values) => submitUpdateCourt(values)} validationSchema={validationSchema} initialValues={initialValuesNorml} enableReinitialize={true}>
                                        {({ errors, setFieldValue, values }) => (
                                            <Form>
                                                <Box display="flex" flexDirection="column" gap="16px">
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Program
                                                        </Typography>
                                                        <Autocomplete
                                                            sx={{ minWidth: "300px" }}
                                                            options={programs}
                                                            value={values?.program}
                                                            disabled={!edit}
                                                            getOptionLabel={(e) => e.name}
                                                            isOptionEqualToValue={(option, value) => option?.id === value.id}
                                                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select program" />}
                                                            onChange={(event, value) => {
                                                                if (value !== null) {
                                                                    //console.log("selecting facture state value", value);
                                                                    //console.log("selecting facture state value", value.id);
                                                                    setFieldValue("program", value);
                                                                    setFieldValue("coach", null);
                                                                    setProgram(value);
                                                                    setFieldValue("duration", null);
                                                                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                                                } else {
                                                                    setFieldValue("program", null);
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage component={FormikErrorText} name="program" />
                                                    </Box>

                                                    <Box mb={2}>
                                                        <Typography fontSize="13px" fontWeight="400">
                                                            Levels
                                                        </Typography>
                                                        <Autocomplete
                                                            multiple
                                                            options={levels}
                                                            value={values.levels || []}
                                                            onChange={(event, newValue) => setFieldValue("levels", newValue)}
                                                            getOptionLabel={(option) => option.name || ""}
                                                            filterSelectedOptions
                                                            renderInput={(params) => (
                                                                <TextField {...params} placeholder="Select level(s)" />
                                                            )}
                                                        />
                                                        <ErrorMessage component={FormikErrorText} name="levels" />
                                                    </Box>
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Start time
                                                        </Typography>
                                                        <Autocomplete
                                                            onChange={(event, value) => {
                                                                if (value !== null) {
                                                                    //console.log("selecting facture state value", value);
                                                                    //console.log("selecting facture state value", value.id);
                                                                    setFieldValue("start_time", value);
                                                                    setStartTime(value);
                                                                    setFieldValue("coach", null);

                                                                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                                                } else {
                                                                    setFieldValue("start_time", null);
                                                                    setStartTime(null);
                                                                    setFieldValue("coach", null);
                                                                }
                                                            }}
                                                            sx={{ minWidth: "300px" }}
                                                            options={hours_time}
                                                            disabled={!edit}
                                                            value={values?.start_time}
                                                            getOptionLabel={(e) => e}
                                                            isOptionEqualToValue={(option, value) => option === value}
                                                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select start time" />}
                                                        />
                                                        <ErrorMessage component={FormikErrorText} name="start_time" />
                                                    </Box>
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Duration
                                                        </Typography>
                                                        <Autocomplete
                                                            sx={{ minWidth: "300px" }}
                                                            options={durations}
                                                            disabled={!edit}
                                                            value={values?.duration ? values?.duration : null}
                                                            getOptionLabel={(e) => e?.duration?.toString()}
                                                            isOptionEqualToValue={(option, value) => option?.duration === value?.duration}
                                                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                                                            onChange={(event, value) => {
                                                                if (value !== null) {
                                                                    setFieldValue("duration", value);
                                                                    setDuration(value);
                                                                    setFieldValue("coach", null);
                                                                } else {
                                                                    setFieldValue("duration", null);
                                                                    setDuration(null);
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage component={FormikErrorText} name="duration" />
                                                    </Box>
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Coach
                                                        </Typography>
                                                        {/*<FormControl sx={{ height: "42px" }} fullWidth>
                                                            <Select
                                                                onChange={(e) => {
                                                                    setFieldValue("coach", e.target?.value);
                                                                }}
                                                                disabled={!edit}
                                                                value={values?.coach}
                                                                placeholder="Select coach"
                                                                sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select">
                                                                {coachs?.map((coach) => (
                                                                    <MenuItem key={coach?.id} value={coach?.id}>
                                                                        <Box display="flex" alignItems="center" gap="5px">
                                                                            <Box marginTop="5px">{coach?.url_avatar ? <img src={coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                                                            <Box>{coach?.first_name + " " + coach?.last_name}</Box>
                                                                        </Box>
                                                                    </MenuItem>
                                                                ))}
                                                               <MenuItem key={values?.coach} value={values?.coach}>
                                                                    <Box display="flex" alignItems="center" gap="5px">
                                                                        <Box marginTop="5px">{values?.coach?.url_avatar ? <img src={values?.coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                                                        <Box>{values?.coach_first_name + " " + values?.coach_last_name}</Box>
                                                                    </Box>
                                                                </MenuItem>
                                                            </Select>                                                           

                                                        </FormControl>*/}

                                                        <FormControl sx={{ height: "42px" }} fullWidth>
                                                            <Select
                                                                onChange={(e) => {
                                                                    setFieldValue("coach", e.target.value); // On passe directement l'ID (string)
                                                                }}
                                                                disabled={!edit}
                                                                value={values?.coach?.id || values?.coach || ''}
                                                                placeholder="Select coach"
                                                                sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                            >
                                                                {/* Coach initial s'il existe et n'est pas dans la liste coachs */}
                                                                {values?.coach && !coachs?.find(c => c.id === values.coach.id) && (
                                                                    <MenuItem key={values?.coach?.id} value={values?.coach?.id || values?.coach}>
                                                                        <Box display="flex" alignItems="center" gap="5px">
                                                                            <Box marginTop="5px">
                                                                                {values?.coach?.url_avatar ? (
                                                                                    <img
                                                                                        src={values?.coach?.url_avatar}
                                                                                        style={{ height: "32px", width: "32px", borderRadius: "50%" }}
                                                                                        alt="profile-pic"
                                                                                    />
                                                                                ) : (
                                                                                    <PersonIcon sx={{ fontSize: "25px" }} />
                                                                                )}
                                                                            </Box>
                                                                            <Box>
                                                                                {values?.coach_first_name
                                                                                    ? values?.coach_first_name + " " + values?.coach_last_name
                                                                                    : values?.coach?.first_name + " " + values?.coach?.last_name
                                                                                }
                                                                            </Box>
                                                                        </Box>
                                                                    </MenuItem>
                                                                )}

                                                                {/* Liste des autres coachs */}
                                                                {coachs?.map((coach) => (
                                                                    <MenuItem key={coach?.id} value={coach?.id}> {/* value est l'ID (string) */}
                                                                        <Box display="flex" alignItems="center" gap="5px">
                                                                            <Box marginTop="5px">
                                                                                {coach?.url_avatar ? (
                                                                                    <img
                                                                                        src={coach?.url_avatar}
                                                                                        style={{ height: "32px", width: "32px", borderRadius: "50%" }}
                                                                                        alt="profile-pic"
                                                                                    />
                                                                                ) : (
                                                                                    <PersonIcon sx={{ fontSize: "25px" }} />
                                                                                )}
                                                                            </Box>
                                                                            <Box>{coach?.first_name + " " + coach?.last_name}</Box>
                                                                        </Box>
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <ErrorMessage component={FormikErrorText} name="coach" />
                                                    </Box>
                                                    {edit && (
                                                        <Box display="flex" gap="16px" alignItems="center">
                                                            <Button type="submit" variant="primary_active">
                                                                Update
                                                            </Button>
                                                            <Button onClick={handleCloseUpdate} variant="primary_inactive">
                                                                Cancel
                                                            </Button>
                                                            <ButtonSquare action="delete_red" onClick={submitDeleteSchedule} />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                                {selectedSessionUpdate !== "normal" && (
                                    <Formik onSubmit={(values) => submitUpdatePrivateCourt(values)} validationSchema={validationSchemaPrivate} initialValues={initialValuesPrv} enableReinitialize={true}>
                                        {({ errors, setFieldValue, values }) => (
                                            <Form>
                                                <Box display="flex" flexDirection="column" gap="16px">
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Start time
                                                        </Typography>
                                                        <Autocomplete
                                                            onChange={(event, value) => {
                                                                if (value !== null) {
                                                                    //console.log("selecting facture state value", value);
                                                                    //console.log("selecting facture state value", value.id);
                                                                    setFieldValue("start_time", value);
                                                                    setStartTime(value);
                                                                    setFieldValue("coach", null);

                                                                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                                                } else {
                                                                    setFieldValue("start_time", null);
                                                                    setStartTime(null);
                                                                }
                                                            }}
                                                            sx={{ minWidth: "300px" }}
                                                            options={hours_time}
                                                            disabled={!edit}
                                                            value={values?.start_time}
                                                            getOptionLabel={(e) => e}
                                                            isOptionEqualToValue={(option, value) => option === value}
                                                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select start time" />}
                                                        />
                                                        <ErrorMessage component={FormikErrorText} name="start_time" />
                                                    </Box>
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Duration
                                                        </Typography>
                                                        <Autocomplete
                                                            sx={{ minWidth: "300px" }}
                                                            options={durations}
                                                            disabled={!edit}
                                                            value={values?.duration ? values?.duration : null}
                                                            getOptionLabel={(e) => e?.duration?.toString()}
                                                            isOptionEqualToValue={(option, value) => option?.duration === value?.duration}
                                                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                                                            onChange={(event, value) => {
                                                                if (value !== null) {
                                                                    setFieldValue("duration", value);
                                                                    setDuration(value);
                                                                    setFieldValue("coach", null);
                                                                } else {
                                                                    setFieldValue("duration", null);
                                                                    setDuration(null);
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage component={FormikErrorText} name="duration" />
                                                    </Box>
                                                    <Box>
                                                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                            Coach
                                                        </Typography>
                                                        <FormControl sx={{ height: "42px" }} fullWidth>
                                                            <Select
                                                                onChange={(e) => {
                                                                    setFieldValue("coach", e.target?.value);
                                                                    console.log(e.target?.value, "e log");
                                                                }}
                                                                value={values?.coach}
                                                                disabled={!edit}
                                                                placeholder="Select coach"
                                                                sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                            >
                                                                {coachs?.map((coach) => (
                                                                    <MenuItem key={coach?.id} value={coach?.id}>
                                                                        <Box display="flex" alignItems="center" gap="5px">
                                                                            <Box marginTop="5px">{coach?.url_avatar ? <img src={coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                                                            <Box>{coach?.first_name + " " + coach?.last_name}</Box>
                                                                        </Box>
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem key={values?.coach} value={values?.coach}>
                                                                    <Box display="flex" alignItems="center" gap="5px">
                                                                        <Box marginTop="5px">{values?.coach?.url_avatar ? <img src={values?.coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                                                        <Box>{values?.coach_first_name + " " + values?.coach_last_name}</Box>
                                                                    </Box>
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <ErrorMessage component={FormikErrorText} name="coach" />
                                                    </Box>
                                                    {edit && (
                                                        <Box display="flex" gap="16px" alignItems="center">
                                                            <Button type="submit" variant="primary_active">
                                                                Update
                                                            </Button>
                                                            <Button onClick={handleCloseUpdate} variant="primary_inactive">
                                                                Cancel
                                                            </Button>
                                                            <ButtonSquare action="delete_red" onClick={submitDeleteSchedule} />
                                                        </Box>
                                                    )}
                                                    {coachs.length === 0 && <Typography sx={{ color: "red", textAlign: "center" }}>No choachs available</Typography>}
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                                <Box marginTop="16px">
                                    <Button disabled={initialValuesNorml?.cancelled_at ? true : false} onClick={() => setOpenCancelSchedule({ open: true, id: openDetails?.data?.id })} variant="primary_active">
                                        Cancel schedule
                                    </Button>
                                    {initialValuesNorml?.cancelled_at && (
                                        <Typography color={"red"} marginTop={"10px"}>
                                            cancelled at : {moment(initialValuesNorml?.cancelled_at).format("DD/MM/YYYY")}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal open={openCancelSchedule?.open} onClose={() => setOpenCancelSchedule({ open: false, id: null })}>
                        <Box sx={modalStyleOverflow}>
                            <Box position="absolute" top="2px" right="2px">
                                <ButtonSquare action="close" onClick={() => setOpenCancelSchedule({ open: false, id: null })} />
                            </Box>
                            <Box>
                                <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
                                    Would you like to refund this session ?
                                </Typography>
                            </Box>
                            <Box marginTop="16px" justifyContent="center" display="flex" gap="16px">
                                <Button disabled={loadingCancel} onClick={() => handleCancelShedule(true)} variant="primary_active">
                                    Refund
                                </Button>
                                <Button disabled={loadingCancel} onClick={() => handleCancelShedule(false)} variant="primary_inactive">
                                    Do not refund
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            </ContentBox>
        </Box>
    );
}

export default WeeklySchedule;