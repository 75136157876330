import { Autocomplete, Box, Button, Chip, CircularProgress, FormControl, MenuItem, Modal, Select, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import ContentBox from "../../components/ui/ContentBox";
import { useEffect, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { player_columns_schedule } from "../../components/data/playerScheduleColumns";
import TableShowUser from "../../components/ui/TableShowUser";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    paddingTop: "8px",
    position: "relative",
    paddingBottom: "8px",
    letterSpacing: "1px",
    color: `#4B465C`,
    fontSize: 13,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    background: "white",
    fontSize: 14,
    color: `#666D83`,
    fontWeight: "500",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  ":hover": {
    filter: "brightness(0.95)",
  },
  cursor: "pointer",
  zIndex: "0",
}));

const StyledTable = styled(Table)({
  borderRadius: "0px",
});
const PlayersGrill = () => {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [court, setCourt] = useState(null);
  const [terms, setTerms] = useState([]);
  const [courts, setCourts] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [data, setData] = useState([]);
  const [program, setProgram] = useState("");
  const [term, setTerm] = useState();
  const [coachFil, setCoachFil] = useState(null);
  const [level, setLevel] = useState(null);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "BTA admin - Players Grill";
  }, []);
  //get coachs filter
  const [users, setUsers] = useState([]);
  useEffect(() => {
    Api.get(Urls?.GET_COACHES, {
      headers: { "content-type": "application/json", Authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  //get list programs
  useEffect(() => {
    console.log(Urls.GET_PROGRAMS, "PROGRAMS");
    Api.get(Urls.GET_PROGRAMS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("programs list:", response.data.data);
        setPrograms(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  useEffect(() => {
    Api.get(Urls.GET_TERMS_LIST, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("terms list:", response.data.data);
        setTerms(response?.data?.data);
        setTerm(response?.data?.data[0]);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  //get list courts
  useEffect(() => {
    Api.get(Urls.GET_COURTS_LIST, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("courts list:", response.data.data);
        setCourts(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  useEffect(() => {
    if (term?.id) {
      setLoading(true);
      Api.get(Urls?.GET_GLOBAL_SCHEDULE + term?.id + "/courtSchedules", {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          courtId: court?.id,
          ...(program?.id && { programId: program?.id }),
          startDatetime: new Date(term?.start_date),
          endDatetime: new Date(term?.end_date),
          ...(coachFil?.id && { coachId: coachFil?.id }),
          ...(level && { levelId: level?.id }),
        },
      })
        .then((res) => {
          setOriginalData(res?.data?.data);
          // Function to get the day name from the start_datetime
          const getDayName = (dateString) => {
            const date = new Date(dateString);
            return date.toLocaleDateString("en-US", { weekday: "long" }).toLowerCase();
          };

          // Helper function to generate a unique key for the group based on level, court, and coach
          /*const generateGroupKey = (session) => {
            return `${session.level_id}_${session.court_id}_${session.coach_id}`;
          };*/
          const generateGroupKey = (session) => {
            const levelIds = session.levels.map(l => l.id).sort().join('-');
            return `${levelIds}_${session.court_id}_${session.coach_id}`;
          };

          const groupSessionsByProgramAndDateAndGroup = (sessions) => {
            return sessions.reduce((acc, session) => {
              const { program_name, start_datetime } = session;
              const day_name = getDayName(start_datetime);

              // Initialize program grouping if not already present
              if (!acc[program_name]) {
                acc[program_name] = {
                  program_name,
                  dates: {},
                };
              }

              const datesGroup = acc[program_name].dates;

              // Initialize date grouping if not already present
              if (!datesGroup[day_name]) {
                datesGroup[day_name] = [];
              }

              // Generate a unique key for the combination of level_id, court_id, and coach_id
              const groupKey = generateGroupKey(session);
              let group = datesGroup[day_name].find((item) => item.groupKey === groupKey);

              if (!group) {
                group = {
                  groupKey,
                  day_name,
                  /*level_id: session.level_id,
                  level_name: session.level_name,*/
                  levels: session.levels,
                  court_id: session.court_id,
                  coach_id: session.coach_id,
                  coach_first_name: session.coach_first_name,
                  coach_last_name: session.coach_last_name,
                  sessions: [],
                  players: [],
                };
                datesGroup[day_name].push(group);
              }

              // Add unique players to the group
              session.players.forEach((player) => {
                if (!group.players.some((p) => p.id === player.id)) {
                  group.players.push(player);
                }
              });

              // Add the session to the appropriate group
              group.sessions.push(session);

              return acc;
            }, {});
          };

          const groupedSessions = groupSessionsByProgramAndDateAndGroup(res?.data?.data);

          // Convert the nested structure into a flat array format if needed
          const resultArray = Object.entries(groupedSessions).map(([programName, programData]) => ({
            program_name: programName,
            dates: Object.values(programData.dates),
          }));

          console.log(resultArray);
          setData(resultArray);
          setLoading(false);
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  }, [program, court, term, coachFil, level]);
  useEffect(() => {
    Api.get(Urls?.GET_LEVELS, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setLevels(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  return (
    <>
      <ToastContainer />
      <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
        <Box>
          <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
            Players grill
          </Typography>
        </Box>
        <ContentBox>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                Weekly schedule grouped by program
              </Typography>
            </Box>
            <Box flexWrap="wrap" display="flex" gap="26px">
              <Box flexWrap={"wrap"} display="flex" flexDirection="row" gap="12px">
                {term && (
                  <Box>
                    <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                      Select term
                    </Typography>
                    <Autocomplete
                      fullWidth
                      sx={{ minWidth: "300px" }}
                      options={terms}
                      value={term}
                      disableClearable={true}
                      getOptionLabel={(e) => e.name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      onChange={(event, value) => {
                        if (value !== null) {
                          //console.log("selecting facture state value", value);
                          //console.log("selecting facture state value", value.id);
                          setTerm(value);
                          //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                        } else {
                          setTerm(null);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select term" />}
                    />
                  </Box>
                )}

                <Box>
                  <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                    Program
                  </Typography>
                  <Autocomplete
                    sx={{ minWidth: "300px" }}
                    options={programs}
                    getOptionLabel={(e) => e.name}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onChange={(event, value) => {
                      if (value !== null) {
                        //console.log("selecting facture state value", value);
                        //console.log("selecting facture state value", value.id);
                        setProgram(value);
                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                      } else {
                        setProgram(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select program" />}
                  />
                </Box>
                <Box>
                  <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                    Select court
                  </Typography>
                  <Autocomplete
                    fullWidth
                    sx={{ minWidth: "300px" }}
                    options={courts}
                    value={court}
                    getOptionLabel={(e) => e.name}
                    isOptionEqualToValue={(option, value) => option?.id === value.id}
                    onChange={(event, value) => {
                      if (value !== null) {
                        //console.log("selecting facture state value", value);
                        //console.log("selecting facture state value", value.id);
                        setCourt(value);
                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                      } else {
                        setCourt(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select court" />}
                  />
                </Box>
                <Box>
                  <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                    Coach
                  </Typography>
                  <Autocomplete
                    sx={{ minWidth: "200px" }}
                    options={users}
                    getOptionLabel={(e) => e.first_name + " " + e.last_name}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onChange={(event, value) => {
                      if (value !== null) {
                        //console.log("selecting facture state value", value);
                        //console.log("selecting facture state value", value.id);
                        setCoachFil(value);
                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                      } else {
                        setCoachFil(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select coach" />}
                  />
                </Box>
                <Box>
                  <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                    Level
                  </Typography>
                  <Autocomplete
                    sx={{ minWidth: "200px" }}
                    options={levels}
                    getOptionLabel={(e) => e.name}
                    isOptionEqualToValue={(option, value) => option?.id === value}
                    onChange={(event, value) => {
                      if (value !== null) {
                        //console.log("selecting facture state value", value);
                        //console.log("selecting facture state value", value.id);
                        setLevel(value);
                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                      } else {
                        setLevel(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select levels" />}
                  />
                </Box>
              </Box>
            </Box>
            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            {!loading && (
              <>
                {data?.map((d) => (
                  <>
                    {d?.program_name !== "null" && (
                      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        <Typography>
                          {/*<Chip sx={{ fontSize: "20px", color: "white", fontWeight: "700", padding: "5px 20px", background: `linear-gradient(-30deg, #${originalData?.filter((o) => o.program_name === d?.program_name)[0]?.program_color_start}, #${originalData?.filter((o) => o.program_name === d?.program_name)[0]?.program_color_end})` }} label={d.program_name} />*/}
                          <Chip
                              sx={{
                                fontSize: "20px",
                                color: "white",
                                fontWeight: "700",
                                padding: "5px 20px",
                                background: d.dates[0]?.[0]?.sessions[0]?.program_color_start
                                    ? `linear-gradient(-30deg, #${d.dates[0][0].sessions[0].program_color_start}, #${d.dates[0][0].sessions[0].program_color_end})`
                                    : '#ccc'
                              }}
                              label={d.program_name}
                          />
                        </Typography>
                        <TableContainer sx={{ maxHeight: "600px", borderRadius: "0px", border: `1px solid #DBDADE` }}>
                          <StyledTable aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                {player_columns_schedule.map((col, index) => (
                                  <StyledTableCell key={index} width={col.width} align={col.align}>
                                    {col.name}
                                  </StyledTableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {d?.dates?.map((player, index) => (
                                <>
                                  {player?.map((k) => (
                                    <>
                                      {k?.players?.length > 0 && k?.players?.filter((player) => player?.is_dropin !== 0)?.length > 0 && (
                                        <StyledTableRow key={player?.id}>
                                          <StyledTableCell align="left">
                                            <Typography fontWeight="400" fontSize="15px">
                                              {k?.day_name?.toUpperCase() + " " + moment(k?.sessions[0]?.start_datetime).format("HH:mm A")}
                                            </Typography>
                                          </StyledTableCell>
                                          <StyledTableCell align="left">{k?.sessions[0]?.coach_first_name + " " + k?.sessions[0]?.coach_last_name}</StyledTableCell>
                                          <StyledTableCell align="left">{k?.sessions[0]?.court_id}</StyledTableCell>
                                          {/*<StyledTableCell align="center">
                                            <Chip variant="primary" label={k?.sessions[0]?.level_name} />
                                          </StyledTableCell>*/}
                                          <StyledTableCell align="center">
                                            <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                                              {k?.sessions[0]?.levels?.map(level => (
                                                  <Chip
                                                      key={level.id}
                                                      variant="primary"
                                                      label={level.name}
                                                      sx={{ margin: '2px' }}
                                                  />
                                              ))}
                                            </Box>
                                          </StyledTableCell>

                                          <StyledTableCell align="center">{k?.sessions[0]?.duration} Min</StyledTableCell>
                                          <StyledTableCell align="left">
                                            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "5px" }}>
                                              {k?.players?.map((p) => (
                                                <>
                                                  {p?.is_dropin !== 0 && (
                                                    <Box onClick={() => navigate(`/admin/players/${p?.id}`)}>
                                                      <Chip sx={{ fontSize: "16px", padding: "10px 5px", ":hover": { background: "red", color: "white" } }} variant="primary" label={p?.first_name + " " + p?.last_name} />
                                                    </Box>
                                                  )}
                                                </>
                                              ))}
                                            </Box>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      )}
                                    </>
                                  ))}
                                </>
                              ))}
                            </TableBody>
                          </StyledTable>
                        </TableContainer>
                      </Box>
                    )}
                  </>
                ))}
              </>
            )}
          </Box>
        </ContentBox>
      </Box>
    </>
  );
};
export default PlayersGrill;
