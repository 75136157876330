export const Urls = {
  //auth
  LOGIN_URL: "/auth/login/admin",
  LOGOUT: "/auth/logout",

  //terms
  GET_TERMS_LIST: "/terms",
  CREATE_TERM: "/terms",
  DELETE_TERM: "/terms",
  UPDATE_TERM: "/terms",
  GET_TERM: "/terms",
  GET_WEEKLY_SCHEDULE: "/terms/",
  DUPLICATE_TERM: "/terms/",
  PUBLISH_TERM: "/terms/",
  GET_GLOBAL_SCHEDULE: "/terms/",

  //courts
  GET_COURTS_LIST: "/courts",
  CREATE_COURT: "/courts",
  DELETE_COURT: "/courts",
  UPDATE_COURT: "/courts",
  GET_COURT: "/courts",

  //players
  GET_PLAYERS_LIST: "/players",
  GET_PLAYER: "/players",
  GET_PLAYERS_OF_CLIENT: "/clients/",
  GET_PLAYERS_BOOKING: "/players/",

  //users
  GET_USERS: "/users",
  GET_USER: "/users/",
  UPDATE_USER_AVATAR: "/users/",
  UPDATE_USER: "/users/",
  DELETE_USER: "/users/",
  CREATE_USERS: "/clients",
  GET_USERS_ORDERS: "/users/",
  UPDATE_USER_PASSWORD: "/users/",

  //coaches
  GET_COACHES: "/coaches",
  GET_COACH_ID: "/coaches/",
  CREATE_COACH: "/coaches",
  GET_AVAILABLE_COACHS: "/available-coaches",

  //private lessons
  CREATE_PRIVATE_LESSONS: "/privateLessons",
  GET_PRIVATE_LESSONS: "/privateLessons",
  DELETE_PRIVATE_LESSON: "/privateLessons",
  CREATE_PRIVATE_LESSON_NBR_PLAYERS: "/privateLessonNbrPlayers",
  UPDATE_PRIVATE_LESSON_NBR_PLAYERS: "/privateLessonNbrPlayers",
  DELETE_PRIVATE_LESSON_NBR_PLAYERS: "/privateLessonNbrPlayers",
  CREATE_PRIVATE_LESSON_PACKS: "/privateLessonPacks",
  UPDATE_PRIVATE_LESSON_PACKS: "/privateLessonPacks",
  DELETE_PRIVATE_LESSON_PACKS: "/privateLessonPacks",
  GET_SINGLE_PRIVATE_LESSON: "/privateLessons",

  //programs
  GET_PROGRAMS: "/programs",
  ADD_PROGRAM: "/programs",
  DELETE_PROGRAM: "/programs/",
  UPDATE_PROGRAM: "/programs/",

  //schedule
  CREATE_NORMAL_SESSION: "/courtWeeklySchedules",
  UPDATE_COURT_WEEKLY_SCHEDULE: "/courtWeeklySchedules/",
  DELETE_COURT_WEEKLY_SCHEDULE: "/courtWeeklySchedules/",
  CANCEL_APPOINTEMENT: "/courtSchedules/",
  UPDATE_PRIVATE_LESSON_GLOBAL: "/courtSchedules/",
  REPLACE_PROGRAM_SCHEDULE: "/courtSchedules",
  GET_PLAYERS_COURT_SCHEDULE: "/courtSchedules/",
  GET_PLANNINGS: "/courtSchedules/",

  GET_PLANNING: "/courtWeeklySchedules",
  POST_PLANNING: "/verify-schedules",
  PLAYER_PRESENCE: "/courtSchedules/",
  CANCEL_WEEKLY_SCHEDULE: "/courtWeeklySchedules/",

  ADD_DROP_IN_PLAYER: "/courtSchedule/add-dropin",
  //bookings
  POST_CANCEL_BOOKIGN: "/bookings/",
  POST_ADD_BOOKING: "/bookings",
  GET_PLAYER_BOOKING: "/bookings/",
  //store
  PATCH_ORDER: "/orders/",
  //categories
  STORE_CATEGORY_CREATE: "/categories",
  STORE_CATEGORY_UPDATE: "/categories",
  STORE_GET_CATEGORIES: "/categories",
  STORE_CATEGORY_DELETE: "/categories",

  //products
  STORE_PRODUCT_CREATE: "/products",
  STORE_PRODUCT_UPDATE: "/products",
  STORE_GET_PRODUCTS: "/products",
  STORE_PRODUCT_DELETE: "/products",
  STORE_PRODUCT_SHOW: "/products",

  //LEVELS
  GET_LEVELS: "/levels",
  CREATE_LEVELS: "/levels",
  UPDATE_LEVELS: "/levels",
  DELETE_LEVELS: "/levels",

  //SETTINGS
  GET_SETTINGS: "/settings",
  UPDATE_SETTINGS: "/settings/",

  //dashboard
  GET_BOOKING_TOTAL: "/dashboard/bookings/total",
  GET_TOTAL_CLIENTS: "/dashboard/clients",
  GET_TOTAL_PLAYERS: "/dashboard/players",
  GET_TOTAL_REV: "/dashboard/revenue",
  GET_BOOKING: "/bookings",
  GET_ORDERS: "/orders",
  GET_COACH_REPORTS: "/coachReports",
  GET_BOOKING_REPORTS: "/bookingReports",

  //players
  DELETE_PLAYER: "/players/",
  CREATE_PLAYER: "/players",
  UPDATE_PLAYER: "/players/",
  RESTORE_PLAYER: "/players/",
  UPDATE_AVATAR_PLAYER: "/players/",
  GET_PLAYER_QUESTIONS: "/questions",

  //slides
  ADD_SLIDE: "/slides",
  DELETE_SLIDE: "/slides/",
  GET_SLIDE: "/slides",

  //notifs
  GET_USER_NOTIFS: "/users/",
};
