import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    Modal,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Stack,
} from "@mui/material";
import { Add, Delete, ExpandMore } from "@mui/icons-material";
import styled from "@emotion/styled";
import { tableCellClasses } from "@mui/material/TableCell";
import ContentBox from "../../components/ui/ContentBox";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import * as Yup from "yup";
import { modalStyleOverflow } from "../../theme/ModalStyle";
import { level_columns } from "../../components/data/levelColumns";

function Levels() {
    const { user } = useAuthContext();
    const access_token = user?.access_token;
    const navigate = useNavigate();
    const [levels, setLevels] = useState([]);
    const [openAddLevel, setOpenAddLevel] = useState(false);
    const [openEditLevel, setOpenEditLevel] = useState({ open: false, data: null });
    const [openDeleteLevel, setOpenDeleteLevel] = useState({ open: false, data: null });
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [change, setChange] = useState(false);
    const [editValues, setEditValues] = useState(null);

    // Styled components pour la table
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "white",
            paddingTop: "8px",
            paddingBottom: "8px",
            letterSpacing: "1px",
            color: "#4B465C",
            fontSize: 13,
            fontWeight: 500,
        },
        [`&.${tableCellClasses.body}`]: {
            background: "white",
            fontSize: 14,
            color: "#666D83",
            fontWeight: 500,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:last-child td, &:last-child th": {
            border: 0,
        },
        ":hover": {
            filter: "brightness(0.95)",
        },
        cursor: "pointer",
    }));

    const StyledTable = styled(Table)({
        borderRadius: "0px",
    });

    // Valeurs initiales pour le formulaire de création/édition
    const initialValues = {
        ageGroups: [
            {
                minAge: "",
                maxAge: "",
                hasQuestions: false,
                questions: [],
            },
        ],
        // Structure des scénarios
        scenarios: [],
    };

    // Schéma de validation Yup incluant les scénarios
    const validationSchema = Yup.object().shape({
        ageGroups: Yup.array().of(
            Yup.object().shape({
                minAge: Yup.number()
                    .required("Required")
                    .min(0, "Min Age cannot be negative"),
                maxAge: Yup.number()
                    .required("Required")
                    .moreThan(Yup.ref('minAge'), "Max Age must be greater than Min Age"),
                questions: Yup.array().of(
                    Yup.object().shape({
                        questionText: Yup.string().required("Required"),
                        answers: Yup.array().of(Yup.string().required("Required")),
                    })
                ),
            })
        ),
        scenarios: Yup.array().of(
            Yup.object().shape({
                ageGroupIndex: Yup.number().required("Required"),
                answers: Yup.array().of(Yup.string().required("Required")),
                scenarioLevel: Yup.string().required("Required"),
            })
        ),
    });



    const updateValidationSchema = Yup.object({
        name: Yup.string().required("Required"),

        ageGroup: Yup.object({
            minAge: Yup.number()
                .required("Required")
                .min(0, "Min Age cannot be negative"),
            maxAge: Yup.number()
                .required("Required")
                .moreThan(Yup.ref("minAge"), "Max Age must be greater than Min Age"),
        }),

        scenario: Yup.object({
            question: Yup.string().trim(),
            answer: Yup.string().trim(),
        })
            .nullable()
            .transform((value, originalValue) => {
                // Si originalValue est un objet et que les deux champs sont vides (après trim),
                // transformer en null.
                if (
                    originalValue &&
                    typeof originalValue === "object" &&
                    (!originalValue.question || originalValue.question.trim() === "") &&
                    (!originalValue.answer || originalValue.answer.trim() === "")
                ) {
                    return null;
                }
                return value;
            })
            .test(
                "scenario-test",
                "Both question and answer must be provided or both must be empty",
                function (value) {
                    // Si value est null, c'est OK
                    if (value === null) return true;
                    const { question, answer } = value;
                    // Si les deux champs sont renseignés (non vides), c'est valide
                    if (question && answer) return true;
                    return false;
                }
            ),
    });






    useEffect(() => {
        document.title = "BTA admin - Levels";
    }, []);

    // Récupération des niveaux via l'API
    useEffect(() => {
        setLoading(true);
        Api.get(Urls.GET_LEVELS, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
            params: {
                ...(search && { search: search }),
            },
        })
            .then((res) => {
                console.log('levels', res?.data?.data);
                setLevels(res?.data?.data);
                setLoading(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
                setLoading(false);
            });
    }, [change, search, access_token]);

    // Création d'un nouveau level
    const handleSubmitCreate = (values) => {
        console.log("Submit Create", values);
        Api.post(
            Urls.CREATE_LEVELS,
            { data: values },
            {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
            }
        )
            .then(() => {
                toast.success("Level Created Successfully");
                setChange(!change);
                setOpenAddLevel(false);
            })
            .catch((err) => toast.error(err.response?.data?.message));
    };

    // Mise à jour d'un level
    const handleSubmitUpdate = (values) => {
        const requestData = {
            data: {
                name: values.name,
                ageGroup: {
                    minAge: values.ageGroup.minAge,
                    maxAge: values.ageGroup.maxAge,
                },
                // On map sur values.scenario pour récupérer tous les éléments
                questions: Array.isArray(values.scenario) && values.scenario.length
                    ? values.scenario.map(sc => ({
                        question: sc.question,
                        answer: sc.answer,
                    }))
                    : [],
            },
        };
        console.log("Submit Update", requestData);

        Api.patch(
            `${Urls.UPDATE_LEVELS}/${openEditLevel.data.id}`,
            requestData,
            {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
            }
        )
            .then(() => {
                toast.success("Level Updated Successfully");
                setChange(!change);
                setOpenEditLevel({ open: false, data: null });
                setEditValues(null);
            })
            .catch((err) => toast.error(err.response?.data?.message));
    };


    // Suppression d'un level
    const handleDeleteLevel = (levelId) => {
        Api.delete(`${Urls.DELETE_LEVELS}/${levelId}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
        })
            .then(() => {
                toast.success("Level Deleted Successfully");
                setChange(!change);
                setOpenDeleteLevel({ open: false, data: null });
            })
            .catch((err) => toast.error(err.response?.data?.message));
    };

    return (
        <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
            <ToastContainer />
            <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
                Levels
            </Typography>

            <ContentBox>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="16px">
                    <Button sx={{ height: "38px" }} variant="primary_active" onClick={() => setOpenAddLevel(true)}>
                        Create Levels
                    </Button>
                    <TextField value={search} onChange={(e) => setSearch(e.target.value)} type="input" placeholder="Search level" />
                </Box>
            </ContentBox>

            <ContentBox>
                {!loading ? (
                    levels.length > 0 ? (
                        <TableContainer sx={{ borderRadius: "0px", border: "1px solid #DBDADE" }}>
                            <StyledTable aria-label="levels table">
                                <TableHead>
                                    <TableRow>
                                        {level_columns.map((col, index) => (
                                            <StyledTableCell key={index} width={col.width} align={col.align}>
                                                {col.name}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {levels.map((level) => (
                                        <StyledTableRow key={level.id}>
                                            <StyledTableCell align="left">
                                                <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                                                    #{level.id}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{level.name || "No Name"}</StyledTableCell>
                                            <StyledTableCell align="left">
                                                <Box display="flex" gap="16px">
                                                    {/*<ButtonSquare
                                                        action="edit_outlined"
                                                        onClick={() => {
                                                            const formattedData = {
                                                                name: level.name,
                                                                // On n'a plus besoin de attribute
                                                                ageGroup: {
                                                                    minAge: level.age_min,
                                                                    maxAge: level.age_max,
                                                                },
                                                                // Si le level a des scénarios, on prend le premier
                                                                scenario: level.scenarios && level.scenarios.length > 0
                                                                    ? {
                                                                        question: level.scenarios[0].question ? level.scenarios[0].question.value : "",
                                                                        answer: level.scenarios[0].answer ? level.scenarios[0].answer.value : "",
                                                                    }
                                                                    : null,
                                                            };

                                                            setOpenEditLevel({ open: true, data: level });
                                                            setEditValues(formattedData);
                                                        }}
                                                    />*/}
                                                    <ButtonSquare
                                                        action="edit_outlined"
                                                        onClick={() => {
                                                            const formattedData = {
                                                                name: level.name,
                                                                // On n'a plus besoin de attribute
                                                                ageGroup: {
                                                                    minAge: level.age_min,
                                                                    maxAge: level.age_max,
                                                                },
                                                                // Si le level a des scénarios, on prend tous les éléments
                                                                scenario: level.scenarios && level.scenarios.length > 0
                                                                    ? level.scenarios.map(scenario => ({
                                                                        question: scenario.question ? scenario.question.value : "",
                                                                        answer: scenario.answer ? scenario.answer.value : "",
                                                                    }))
                                                                    : null,
                                                            };

                                                            setOpenEditLevel({ open: true, data: level });
                                                            setEditValues(formattedData);
                                                        }}
                                                    />



                                                    <ButtonSquare action="delete_red" onClick={() => setOpenDeleteLevel({ open: true, data: level })} />
                                                </Box>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </StyledTable>
                        </TableContainer>
                    ) : (
                        <Typography textAlign="center">No levels</Typography>
                    )
                ) : (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </ContentBox>

            {/* ================= Modal de Création ================= */}
            <Modal open={openAddLevel} onClose={() => setOpenAddLevel(false)}>
                <Box sx={modalStyleOverflow}>
                    <Box position="absolute" top="2px" right="2px">
                        <ButtonSquare action="close" onClick={() => setOpenAddLevel(false)} />
                    </Box>
                    <Box>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmitCreate}>
                            {({ values, errors, setFieldValue }) => (
                                <Form>
                                    <Box marginBottom="26px">
                                        <Typography textAlign="left" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                                            Add Levels
                                        </Typography>
                                    </Box>
                                    <FieldArray name="ageGroups">
                                        {({ push, remove }) => (
                                            <Box>
                                                {values.ageGroups.map((group, index) => (

                                                        <Box key={index} border={1} borderColor={"grey.300"} borderRadius={"5px"} mb={2}>
                                                            <Accordion key={index} defaultExpanded>
                                                                <AccordionSummary expandIcon={<ExpandMore />}>
                                                                    <Typography variant="h6">Age Group {index + 1}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Box display="flex" gap="16px" flexWrap="wrap" mt={2}>
                                                                        <Box flex={1}>
                                                                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                                                Min Age
                                                                            </Typography>
                                                                            <Field
                                                                                style={{ width: "100%" }}
                                                                                name={`ageGroups.${index}.minAge`}
                                                                                type="number"
                                                                                id="min_age"
                                                                                as={TextField}
                                                                                autoComplete="off"
                                                                                placeholder="Min Age"
                                                                                inputProps={{ min: 0 }}
                                                                            />
                                                                            <ErrorMessage component={FormikErrorText} name={`ageGroups.${index}.minAge`} />
                                                                        </Box>
                                                                        <Box flex={1}>
                                                                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                                                                Max Age
                                                                            </Typography>
                                                                            <Field
                                                                                style={{ width: "100%" }}
                                                                                name={`ageGroups.${index}.maxAge`}
                                                                                type="number"
                                                                                id="max_age"
                                                                                as={TextField}
                                                                                autoComplete="off"
                                                                                placeholder="Max Age"
                                                                                inputProps={{ min: 0 }}
                                                                            />
                                                                            <ErrorMessage component={FormikErrorText} name={`ageGroups.${index}.maxAge`} />
                                                                        </Box>
                                                                    </Box>

                                                                    <Field name={`ageGroups.${index}.hasQuestions`}>
                                                                        {({ field }) => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...field}
                                                                                        checked={field.value}
                                                                                        onChange={(e) => {
                                                                                            const isChecked = e.target.checked;
                                                                                            setFieldValue(`ageGroups.${index}.hasQuestions`, isChecked);
                                                                                            if (isChecked) {
                                                                                                if (!values.ageGroups[index].questions || values.ageGroups[index].questions.length === 0) {
                                                                                                    setFieldValue(`ageGroups.${index}.questions`, [{ questionText: "", answers: [""] }]);
                                                                                                }
                                                                                            } else {
                                                                                                // Vider le tableau des questions quand le checkbox est décoché
                                                                                                setFieldValue(`ageGroups.${index}.questions`, []);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label="Has Questions"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    {group.hasQuestions && (
                                                                        <Box mt={2}>
                                                                            <Typography variant="subtitle1" gutterBottom>
                                                                                Questions
                                                                            </Typography>
                                                                            <FieldArray name={`ageGroups.${index}.questions`}>
                                                                                {({ push: pushQuestion, remove: removeQuestion }) => (
                                                                                    <Box mt={2}>
                                                                                        {group.questions &&
                                                                                            group.questions.map((question, qIndex) => (
                                                                                                <Box key={qIndex} border={1} borderColor={"grey.300"} borderRadius={"10px"} p={2} mt={2}>
                                                                                                    <Field
                                                                                                        name={`ageGroups.${index}.questions.${qIndex}.questionText`}
                                                                                                        as={TextField}
                                                                                                        label={`Question ${qIndex + 1}`}
                                                                                                        fullWidth
                                                                                                    />
                                                                                                    <ErrorMessage
                                                                                                        name={`ageGroups.${index}.questions.${qIndex}.questionText`}
                                                                                                        component={FormikErrorText}
                                                                                                    />
                                                                                                    <FieldArray name={`ageGroups.${index}.questions.${qIndex}.answers`}>
                                                                                                        {({ push: pushAnswer, remove: removeAnswer }) => (
                                                                                                            <Box mt={2}>
                                                                                                                {question.answers &&
                                                                                                                    question.answers.map((answer, aIndex) => (
                                                                                                                        <Box key={aIndex} display="flex" alignItems="center" gap={2} mb={2}>
                                                                                                                            <Field
                                                                                                                                name={`ageGroups.${index}.questions.${qIndex}.answers.${aIndex}`}
                                                                                                                                as={TextField}
                                                                                                                                label={`Answer ${aIndex + 1}`}
                                                                                                                                fullWidth
                                                                                                                            />
                                                                                                                            <ButtonSquare action="delete_red" onClick={() => removeAnswer(aIndex)} />
                                                                                                                        </Box>
                                                                                                                    ))}
                                                                                                                <Box display={"flex"} justifyContent={"space-between"} mt={2}>
                                                                                                                    <Button size="small" onClick={() => pushAnswer("")} startIcon={<Add />}>
                                                                                                                        Add Answer
                                                                                                                    </Button>
                                                                                                                    <Button size="small" color="error" onClick={() => removeQuestion(qIndex)} startIcon={<Delete />}>
                                                                                                                        Remove Question
                                                                                                                    </Button>
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                    </FieldArray>
                                                                                                </Box>
                                                                                            ))}
                                                                                        <Box display={"flex"} justifyContent={"space-between"} mt={2}>
                                                                                            <Button size="small" onClick={() => pushQuestion({ questionText: "", answers: [""] })} startIcon={<Add />}>
                                                                                                Add Question
                                                                                            </Button>

                                                                                        </Box>
                                                                                    </Box>
                                                                                )}
                                                                            </FieldArray>
                                                                        </Box>
                                                                    )}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            <Box display={"flex"} justifyContent={"end"} mt={2}>
                                                                <Button size="small" color="error" onClick={() => remove(index)} startIcon={<Delete />} disabled={values.ageGroups.length === 1}>
                                                                    Remove Age Group
                                                                </Button>
                                                            </Box>


                                                        </Box>
                                                ))}
                                                <Button onClick={() => push({ minAge: "", maxAge: "", hasQuestions: false, questions: [] })} startIcon={<Add />}>
                                                    Add Age Group
                                                </Button>
                                            </Box>
                                        )}
                                    </FieldArray>

                                    <FieldArray name="scenarios">
                                        {({ push, remove }) => (
                                            <Box mt={4}>
                                                <Typography variant="h5" gutterBottom>
                                                    Scenarios
                                                </Typography>
                                                {values.scenarios &&
                                                    values.scenarios.map((scenario, sIndex) => {
                                                        const ageGroupIdx = scenario.ageGroupIndex;
                                                        const selectedAgeGroup =
                                                            ageGroupIdx !== "" && values.ageGroups[ageGroupIdx]
                                                                ? values.ageGroups[ageGroupIdx]
                                                                : null;
                                                        return (
                                                            <Box key={sIndex} border={1} borderColor={"grey.300"} borderRadius={"10px"} mt={2}>
                                                                <Accordion key={sIndex} defaultExpanded>
                                                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                                                        <Typography variant="subtitle1">Scenario {sIndex + 1}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Box display="flex" flexDirection="column" gap={2} mt={1}>
                                                                            <Field
                                                                                name={`scenarios.${sIndex}.ageGroupIndex`}
                                                                                as={TextField}
                                                                                select
                                                                                label="Select Age Group"
                                                                                fullWidth
                                                                                onChange={(e) => {
                                                                                    const selected = e.target.value;
                                                                                    setFieldValue(`scenarios.${sIndex}.ageGroupIndex`, selected);
                                                                                    const selectedGroup = values.ageGroups[selected];
                                                                                    if (selectedGroup && selectedGroup.questions) {
                                                                                        const answersArray = selectedGroup.questions.map(() => "");
                                                                                        setFieldValue(`scenarios.${sIndex}.answers`, answersArray);
                                                                                    } else {
                                                                                        setFieldValue(`scenarios.${sIndex}.answers`, []);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <MenuItem value="">Select Age Group</MenuItem>
                                                                                {values.ageGroups.map((group, gIndex) => (
                                                                                    <MenuItem key={gIndex} value={gIndex}>
                                                                                        Age Group {gIndex + 1} ({group.minAge} - {group.maxAge})
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Field>
                                                                            {selectedAgeGroup &&
                                                                                selectedAgeGroup.questions &&
                                                                                selectedAgeGroup.questions.map((question, qIndex) => (
                                                                                    <Field
                                                                                        key={qIndex}
                                                                                        name={`scenarios.${sIndex}.answers.${qIndex}`}
                                                                                        as={TextField}
                                                                                        select
                                                                                        label={question.questionText}
                                                                                        fullWidth
                                                                                    >
                                                                                        <MenuItem value="">Select Answer</MenuItem>
                                                                                        {question.answers.map((ans, aIndex) => (
                                                                                            <MenuItem key={aIndex} value={ans}>
                                                                                                {ans}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                ))}
                                                                            <Field
                                                                                name={`scenarios.${sIndex}.scenarioLevel`}
                                                                                as={TextField}
                                                                                label="Level name"
                                                                                fullWidth
                                                                            />
                                                                            {/*<Field
                                                                                name={`scenarios.${sIndex}.scenarioAttribute`}
                                                                                as={TextField}
                                                                                label="Level attribute"
                                                                                fullWidth
                                                                            />*/}
                                                                        </Box>
                                                                        <Box display={"flex"} justifyContent={"end"}>
                                                                            <Button size="small" color="error" onClick={() => remove(sIndex)} startIcon={<Delete />} sx={{ mt: 2 }}>
                                                                                Remove Scenario
                                                                            </Button>
                                                                        </Box>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Box>
                                                        );
                                                    })}
                                                <Button onClick={() => push({ scenarioLevel: "" })} startIcon={<Add />}>
                                                    Add Scenario
                                                </Button>
                                            </Box>
                                        )}
                                    </FieldArray>

                                    <Box mt={2}>
                                        <Button type="submit" variant="primary_active">
                                            Submit Levels and Scenarios
                                        </Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>

            {/* ================= Modal de Mise à Jour ================= */}

            <Modal
                open={openEditLevel.open}
                onClose={() => {
                    setOpenEditLevel({ open: false, data: null });
                    setEditValues(null);
                }}
            >
                <Box sx={modalStyleOverflow}>
                    <Box position="absolute" top="2px" right="2px">
                        <ButtonSquare
                            action="close"
                            onClick={() => {
                                setOpenEditLevel({ open: false, data: null });
                                setEditValues(null);
                            }}
                        />
                    </Box>
                    <Box p={3}>
                        {editValues && (
                            <Formik
                                initialValues={{
                                    name: editValues.name || "",
                                    ageGroup: {
                                        minAge: editValues.ageGroup?.minAge || 0,
                                        maxAge: editValues.ageGroup?.maxAge || 0,
                                    },
                                    // On s'assure d'avoir un tableau pour les scénarios
                                    scenario: editValues.scenario || [],
                                }}
                                validationSchema={updateValidationSchema}
                                onSubmit={handleSubmitUpdate}
                                enableReinitialize
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <Box mb={2}>
                                            <Typography variant="h5" color="#4B465C">
                                                Edit Level
                                            </Typography>
                                        </Box>

                                        {/* Champ Nom */}
                                        <Field
                                            name="name"
                                            as={TextField}
                                            label="Level Name"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <ErrorMessage name="name" component={FormikErrorText} />

                                        {/* Groupe d'âge */}
                                        <Box mt={2}>
                                            <Typography variant="subtitle1">Age Group</Typography>
                                            <Field
                                                name="ageGroup.minAge"
                                                as={TextField}
                                                label="Min Age"
                                                type="number"
                                                fullWidth
                                                margin="normal"
                                                inputProps={{ min: 0 }}
                                            />
                                            <ErrorMessage name="ageGroup.minAge" component={FormikErrorText} />

                                            <Field
                                                name="ageGroup.maxAge"
                                                as={TextField}
                                                label="Max Age"
                                                type="number"
                                                fullWidth
                                                margin="normal"
                                                inputProps={{ min: 0 }}
                                            />
                                            <ErrorMessage name="ageGroup.maxAge" component={FormikErrorText} />
                                        </Box>

                                        {/* Section Scenario (FieldArray pour plusieurs questions/réponses) */}
                                        <Box mt={2}>
                                            <Typography variant="subtitle1">
                                                Scenarios (Questions & Answers)
                                            </Typography>

                                            <FieldArray name="scenario">
                                                {({ push, remove, form }) => (
                                                    <Box>
                                                        {form.values.scenario && form.values.scenario.length > 0 ? (
                                                            form.values.scenario.map((sc, index) => (
                                                                <Box key={index} mb={2} p={2} border="1px solid #ccc" borderRadius={2}>
                                                                    <Typography variant="subtitle2">
                                                                        Scenario {index + 1}
                                                                    </Typography>
                                                                    {/* Champ Question */}
                                                                    <Field
                                                                        name={`scenario.${index}.question`}
                                                                        as={TextField}
                                                                        label="Question"
                                                                        fullWidth
                                                                        margin="normal"
                                                                    />
                                                                    <ErrorMessage name={`scenario.${index}.question`} component={FormikErrorText} />

                                                                    {/* Champ Réponse */}
                                                                    <Field
                                                                        name={`scenario.${index}.answer`}
                                                                        as={TextField}
                                                                        label="Answer"
                                                                        fullWidth
                                                                        margin="normal"
                                                                    />
                                                                    <ErrorMessage name={`scenario.${index}.answer`} component={FormikErrorText} />

                                                                    <Box display="flex" justifyContent="flex-end" mt={1}>
                                                                        <Button
                                                                            size="small"
                                                                            color="error"
                                                                            onClick={() => remove(index)}
                                                                            startIcon={<Delete />}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            ))
                                                        ) : (
                                                            <Typography color="textSecondary">
                                                                No questions/answers found
                                                            </Typography>
                                                        )}

                                                        <Box mt={2}>
                                                            <Button
                                                                size="small"
                                                                onClick={() => push({ question: "", answer: "" })}
                                                                startIcon={<Add />}
                                                            >
                                                                Add Question
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </FieldArray>
                                        </Box>

                                        {/* Bouton de soumission */}
                                        <Box mt={3} display="flex" justifyContent="flex-end">
                                            <Button type="submit" variant="primary_active">
                                                Update Level
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Box>
                </Box>
            </Modal>







            {/* Modal de suppression */}
            <Modal open={openDeleteLevel.open} onClose={() => setOpenDeleteLevel({ open: false, data: null })}>
                <Box sx={modalStyleOverflow}>
                    <Box position="absolute" top="2px" right="2px">
                        <ButtonSquare action="close" onClick={() => setOpenDeleteLevel({ open: false, data: null })} />
                    </Box>
                    <Box mb={2}>
                        <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                            Delete Level
                        </Typography>
                    </Box>
                    <Box>
                        <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
                            Are you sure you want to delete level {openDeleteLevel?.data?.name}?
                        </Typography>
                    </Box>
                    <Box mt={2} display="flex" justifyContent="center" gap="16px">
                        <Button variant="primary_active" onClick={() => handleDeleteLevel(openDeleteLevel?.data?.id)}>
                            Delete
                        </Button>
                        <Button variant="primary_inactive" onClick={() => setOpenDeleteLevel({ open: false, data: null })}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default Levels;
