import { Autocomplete, Box, Button, TextField, Typography, Modal, Avatar, Chip, Divider, CircularProgress, InputAdornment } from "@mui/material";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../components/ui/ContentBox";
import { terms_columns } from "../../components/data/termsColumns";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import { terms_rows } from "../../components/data/termsRows";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import BtaDatePicker from "../../components/ui/BtaDatePicker";
import { modalStyle, modalStyleOverflow } from "../../theme/ModalStyle";
import { courts_columns } from "../../components/data/courtsColumns";
import * as Yup from "yup";
import { programs_columns } from "../../components/data/programsColumns";
import Colorful from "@uiw/react-color-colorful";

function Programs() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [addDurationError, setAddDurationError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addFrequencyError, setAddFrequencyError] = useState(false);
  const [addProgramError, setAddProgramError] = useState(false);
  const [change, setChange] = useState(false);
  const navigate = useNavigate();
  const [levels, setLevels] = useState([]);
  const [search, setSearch] = useState("");
  const [openEditProgram, setOpenEditProgram] = useState({
    id: null,
    open: false,
  });
  const [openDeleteProgram, setOpenDeleteProgram] = useState({
    id: null,
    open: false,
  });
  const [openAddProgram, setOpenAddProgram] = useState(false);
  const [addedDurations, setAddedDurations] = useState([]);
  const [program, setProgram] = useState({
    name: "",
    max_players: "",
    color_start: "",
    color_end: "",
    levels: [],
    description: "",
    durations: {
      duration: "",
      drop_in_price: "",
      frequencies: [
        {
          id: 0,
          frequency_per_week: "",
          price: "",
        },
      ],
    },
  });

  const [data, setData] = useState([]);
  const [programs, setPrograms] = useState([]);
  const perPage = "?perPage=10";
  const [price, setPrice] = useState("");

  const [colorInstanceCreateStart, setColorInstanceCreateStart] = useState("#D0CFCF");
  const [colorInstanceCreateEnd, setColorInstanceCreateEnd] = useState("#D0CFCF");
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const [openColorCreateStart, setOpenColorCreateStart] = useState(false);
  const [openColorCreateEnd, setOpenColorCreateEnd] = useState(false);
  const [errorAddedDurations, setErrorAddedDurations] = useState(false);
  const [colorInstanceCreateStartUpdate, setColorInstanceCreateStartUpdate] = useState(program?.color_start);
  const [colorInstanceCreateEndUpdate, setColorInstanceCreateEndUpdate] = useState(program?.color_start);
  const [openStartUpdate, setOpenStartUpdate] = useState(false);
  const [openEndUpdate, setOpenEndUpdate] = useState(false);
  const [openColorCreateStartUpdate, setOpenColorCreateStartUpdate] = useState(false);
  const [openColorCreateEndUpdate, setOpenColorCreateEndUpdate] = useState(false);
  useEffect(() => {
    document.title = "BTA admin - Programs";
  }, []);
  //get list progs
  useEffect(() => {
    console.log(Urls.GET_PROGRAMS, "PROGRAMS");
    Api.get(Urls.GET_PROGRAMS + perPage, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(search && { search: search }),
      },
    })
      .then((response) => {
        console.log("programs list:", response.data.data);
        setPrograms(response?.data?.data);
        setData(response?.data);
        if (openEditProgram?.open) {
          handleSelectEdit(response?.data?.data?.filter((program) => program?.id === openEditProgram?.id)[0]);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  }, [change, search]);

  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setPrograms(res.data?.data);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setPrograms([]);
      });
  };

  //table style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });
  const initialValuesCreate = {
    name: "",
    max_players: "",
    color_start: "",
    color_end: "",
    levels: [],
    description: "",
    durations: {
      duration: "",
      drop_in_price: "",
      frequencies: [
        {
          id: program?.durations?.frequencies.lenght + 1,
          frequency_per_week: "",
          price: "",
        },
      ],
    },
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const handleOpenEditProgram = (id) => {
    setOpenEditProgram({
      id: id,
      open: true,
    });
  };
  const handleCloseEditProgram = () => {
    setOpenEditProgram({
      id: null,
      open: false,
    });
    setAddDurationError(false);
    setAddFrequencyError(false);
    setAddProgramError(false);
    setAddedDurations([]);
    setProgram({
      name: "",
      max_players: "",
      color_start: "",
      color_end: "",
      levels: [],
      description: "",
      durations: {
        duration: "",
        drop_in_price: "",
        frequencies: [
          {
            id: 0,
            frequency_per_week: "",
            price: "",
          },
        ],
      },
    });
  };
  const handleOpenDeleteProgram = (id) => {
    setOpenDeleteProgram({
      id: id,
      open: true,
    });
  };
  const handleCloseDeleteProgram = () => {
    setOpenDeleteProgram({
      id: null,
      open: false,
    });
  };
  const handleOpenCreateProgram = () => {
    setOpenAddProgram(true);
  };
  const handleCloseCreateProgram = () => {
    setOpenAddProgram(false);
    setAddDurationError(false);
    setAddFrequencyError(false);
    setAddProgramError(false);
    setAddedDurations([]);
    setProgram({
      name: "",
      max_players: "",
      color_start: "",
      color_end: "",
      levels: [],
      description: "",
      durations: {
        duration: "",
        drop_in_price: "",
        frequencies: [
          {
            id: 0,
            frequency_per_week: "",
            price: "",
          },
        ],
      },
    });
    setColorInstanceCreateStart("#D0CFCF");
    setColorInstanceCreateEnd("#D0CFCF");
  };
  const handleCreateProgram = (action) => {
    let error = false;
    if (!program?.name || !program?.max_players || program?.levels?.length === 0 || !program?.color_end || !program?.color_start || !program?.description) {
      setAddProgramError(true);
      error = true;
    } else {
      if (addedDurations?.length === 0) {
        setAddDurationError(true);
        setAddFrequencyError(true);
        error = true;
      } else {
        for (let index = 0; index < addedDurations?.length; index++) {
          const elementD = addedDurations[index];
          if (!elementD?.drop_in_price || !elementD?.duration || !elementD?.frequencies) {
            setAddFrequencyError(true);
            error = true;

            setAddDurationError(true);
          } else {
            for (let index = 0; index < elementD?.frequencies?.length; index++) {
              const element = elementD?.frequencies[index];
              if (!element?.price || !element?.frequency_per_week) {
                setAddFrequencyError(true);
                error = true;
              } else {
                error = false;
              }
            }
          }
        }
      }
    }
    if (error === false && action === "create" && addedDurations?.length > 0) {
      setAddDurationError(false);
      setAddFrequencyError(false);
      setErrorAddedDurations(false);
      let levelsIds = [];
      for (let index = 0; index < program?.levels.length; index++) {
        const element = program?.levels[index];
        levelsIds.push(element?.id);
      }
      setAddProgramError(false);
      Api.post(
        Urls?.ADD_PROGRAM,
        {
          name: program?.name,
          max_players: program?.max_players,
          color_start: program?.color_start.slice(1),
          color_end: program?.color_end.slice(1),
          description: program?.description,
          levels: levelsIds,
          durations: addedDurations,
        },
        {
          headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        }
      )
        .then((res) => {
          toast?.success(res?.data?.message);
          setChange(!change);
          handleCloseCreateProgram();
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
        });
    }
    if (addedDurations?.length === 0) {
      setErrorAddedDurations(true);
    }
    if (error === false && action === "update") {
      setAddDurationError(false);
      setAddFrequencyError(false);
      setAddProgramError(false);
      setErrorAddedDurations(true);

      let levelsIds = [];
      for (let index = 0; index < program?.levels.length; index++) {
        const element = program?.levels[index];
        levelsIds.push(element?.id);
      }
      Api.patch(
        Urls?.UPDATE_PROGRAM + openEditProgram?.id,
        {
          name: program?.name,
          max_players: program?.max_players,
          color_start: program?.color_start.slice(1),
          color_end: program?.color_end.slice(1),
          description: program?.description,
          levels: levelsIds,
        },
        {
          headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        }
      )
        .then((res) => {
          toast?.success(res?.data?.message);
          setChange(!change);
          handleCloseEditProgram();
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
        });
    }
  };
  const handleDeleteProgram = () => {
    Api?.delete(Urls?.DELETE_PROGRAM + openDeleteProgram?.id, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast?.success(res?.data?.message);
        setChange(!change);
        handleCloseDeleteProgram();
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleSelectEdit = (program) => {
    setProgram({
      name: program?.name,
      max_players: program?.max_players,
      color_start: "#" + program?.color_start,
      color_end: "#" + program?.color_end,
      levels: program?.levels,
      description: program?.description,
      durations: {
        duration: "",
        drop_in_price: "",
        frequencies: [
          {
            id: 0,
            frequency_per_week: "",
            price: "",
          },
        ],
      },
    });
    console.log(program?.program_durations, "duration do update");
    let durationsArray = [];
    for (let index = 0; index < program?.program_durations?.length; index++) {
      const element = program?.program_durations[index];
      let elementOriginalArray = element.program_duration_frequencies;
      const updatedListFreq = elementOriginalArray.map(({ created_at, ...rest }) => rest);
      element.frequencies = updatedListFreq;
      console.log(updatedListFreq, "updated list freq");
      durationsArray.push(element);
    }
    const updatedList = durationsArray.map(({ program_duration_frequencies, created_at, ...rest }) => rest);
    setAddedDurations(updatedList);
    setColorInstanceCreateStartUpdate("#" + program?.color_start);
    setColorInstanceCreateEndUpdate("#" + program?.color_end);
  };
  const handleSaveFrequency = (frequency) => {
    Api.patch(
      Urls?.UPDATE_PROGRAM + openEditProgram?.id,
      {
        frequencies_to_update: [
          {
            id: frequency?.id,
            frequency_per_week: frequency?.frequency_per_week,
            price: parseFloat(frequency?.price),
          },
        ],
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleSaveDurations = (duration) => {
    Api.patch(
      Urls?.UPDATE_PROGRAM + openEditProgram?.id,
      {
        durations_to_update: [
          {
            id: duration?.id,
            duration: duration?.duration,
            drop_in_price: parseFloat(duration?.drop_in_price),
          },
        ],
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleAddDurations = (duration) => {
    Api.patch(
      Urls?.UPDATE_PROGRAM + openEditProgram?.id,
      {
        durations_to_add: [duration],
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setProgram({
          ...program,
          durations: {
            duration: "",
            drop_in_price: "",
            frequencies: [
              {
                id: 0,
                frequency_per_week: "",
                price: "",
              },
            ],
          },
        });
        setChange(!change);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleAddFrequencies = (frequency, id) => {
    Api.patch(
      Urls?.UPDATE_PROGRAM + openEditProgram?.id,
      {
        frequencies_to_add: [
          {
            program_duration_id: id,
            frequency_per_week: frequency?.frequency_per_week,
            price: parseInt(frequency?.price),
          },
        ],
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleDeleteFrequencies = (frequency) => {
    Api.patch(
      Urls?.UPDATE_PROGRAM + openEditProgram?.id,
      {
        frequencies_to_delete: [frequency?.id],
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleDeleteDuration = (duration) => {
    Api.patch(
      Urls?.UPDATE_PROGRAM + openEditProgram?.id,
      {
        durations_to_delete: [duration?.id],
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    Api.get(Urls?.GET_LEVELS, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setLevels(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  return (
    <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
      <ToastContainer />
      <Box>
        <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
          Programs
        </Typography>
      </Box>
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <ContentBox>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                List of programs
              </Typography>
            </Box>
            <Box marginTop="8px" flexWrap="wrap" display="flex" gap="16px" flexDirection="row" justifyContent="space-between">
              <Box>
                <Button onClick={handleOpenCreateProgram} sx={{ height: "38px" }} variant="primary_active">
                  Create program
                </Button>
              </Box>
              <TextField value={search} onChange={handleSearchChange} type="input" placeholder="Search program" />
            </Box>
            <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
              <StyledTable aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {programs_columns.map((col, index) => (
                      <StyledTableCell key={index} width={col.width} align={col.align}>
                        {col.name}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {programs?.map((program) => (
                    <StyledTableRow key={program?.id}>
                      <StyledTableCell align="left">
                        <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                          #{program?.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box display="flex" alignItems="center" gap="10px">
                          <Box height="46px" width="46px" borderRadius="6px" sx={{ background: `linear-gradient(to right, #${program?.color_start}, #${program?.color_end})` }}></Box>
                          <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                            {program?.name}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">{program?.max_players}</StyledTableCell>

                      <StyledTableCell align="left">
                        <Box display="flex" flexWrap="wrap" gap="10px">
                          {program?.levels?.map((level) => (
                            <Chip variant="primary" label={level?.name} key={level?.id} />
                          ))}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box justifyContent="left" display="flex" gap="16px">
                          <ButtonSquare
                            action="edit_outlined"
                            onClick={() => {
                              handleOpenEditProgram(program?.id);
                              handleSelectEdit(program);
                            }}
                          />

                            <ButtonSquare
                              action="delete_red"
                              onClick={() => {
                                handleOpenDeleteProgram(program?.id);
                              }}
                            />
                         {/* {program?.id > 9 && (
                          )}*/}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>

            {programs?.length === 0 && !loading && <Typography textAlign="center">No programs</Typography>}
            {programs?.length > 0 && (
              <Box justifyContent="flex-end" display="flex" gap="4px">
                {data?.links?.prev && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(data?.links?.prev);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action="Previous"
                  />
                )}
                <Box display="flex" gap="4px" justifyContent="center">
                  {data?.meta?.links?.map((link) => (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(link.url);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action={link?.active ? "number-active" : "number"}
                      number={link.label}
                    />
                  ))}
                </Box>
                {data?.links?.next && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(data?.links?.next);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action="Next"
                  />
                )}
              </Box>
            )}
          </Box>
        </ContentBox>
      )}
      {/*modals */}
      <Modal open={openAddProgram} onClose={handleCloseCreateProgram}>
        <Box sx={modalStyleOverflow}>
          {/* <pre>{JSON.stringify(program, null, 2)}</pre> */}
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseCreateProgram} />
          </Box>
          <Box marginBottom="26px">
            <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Create Program
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                Program name
              </Typography>
              <TextField onChange={(e) => setProgram({ ...program, name: e.target.value })} value={program?.name} style={{ width: "100%" }} name="name" type="input" id="name" autoComplete="off" placeholder="program 1" />
              <FormikErrorText>{!program?.name && addProgramError && "This field is mandatory"}</FormikErrorText>
            </Box>
            <Box>
              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                Max number of booking players BY SEANCE
              </Typography>
              <TextField
                inputProps={{
                  min: 0,
                }}
                onChange={(e) => setProgram({ ...program, max_players: e.target.value })}
                value={program?.max_players}
                style={{ width: "100%" }}
                name="max_players"
                type="number"
                id="max_players"
                autoComplete="off"
                placeholder="number of booking players"
              />
              <FormikErrorText>{!program?.max_players && addProgramError && "This field is mandatory"}</FormikErrorText>
            </Box>
            <Box>
              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                Level
              </Typography>

              <Autocomplete
                sx={{ minWidth: "300px" }}
                options={levels}
                multiple
                isOptionEqualToValue={(option, value) => option?.id === value.id}
                getOptionLabel={(e) => e?.name}
                onChange={(event, value) => {
                  if (value !== null) {
                    setProgram({ ...program, levels: value });
                  } else {
                  }
                }}
                renderInput={(params) => <TextField {...params}
                                                    name="autocomplete" placeholder="Select levels" />}
              />
              <FormikErrorText>{program?.levels?.length === 0 && addProgramError && "This field is mandatory"}</FormikErrorText>
            </Box>
            <Box display="flex" flexDirection="row" gap="16px" flexWrap="wrap">
              <Box>
                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                  Start color
                </Typography>
                {/* <TextField onChange={(e) => setProgram({ ...program, color_start: e.target.value })} value={program?.color_start} style={{ width: "100%" }} name="color_start" type="input" id="color_start" autoComplete="off" placeholder="ffffff" /> */}
                <Box display="flex" flexDirection="row" gap="10px" sx={{ width: "100%" }}>
                  <Box position="relative" flex={1}>
                    <TextField
                      autoComplete="off"
                      name="color_start"
                      placeholder="#000000"
                      id="color_start"
                      value={colorInstanceCreateStart}
                      fullWidth
                      onChange={(e) => {
                        if (e.target?.value?.length < 8) {
                          setColorInstanceCreateStart(e.target.value);
                          setProgram({ ...program, color_start: e.target.value });
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              onClick={() => setOpenStart(openStart ? false : true)}
                              sx={{
                                width: "28px",
                                height: "28px",
                                borderRadius: "6px",
                                bgcolor: `${colorInstanceCreateStart}`,
                                cursor: "pointer",
                                "&:hover": {
                                  filter: "brightness(0.8)",
                                },
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box display={openStart ? "flex" : "none"} sx={{ zIndex: "99", position: "absolute", top: "42px", left: "0px" }}>
                      <Colorful
                        disableAlpha
                        color={colorInstanceCreateStart}
                        onChange={(color) => {
                          console.log(color, "color");
                          setColorInstanceCreateStart(color.hex);
                          setProgram({ ...program, color_start: color.hex });
                        }}
                        onMouseLeave={() => setOpenStart(false)}
                      />
                    </Box>
                  </Box>
                </Box>
                <FormikErrorText>{!program?.color_start && addProgramError && "This field is mandatory"}</FormikErrorText>
              </Box>
              <Box>
                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                  End color
                </Typography>
                {/* <TextField onChange={(e) => setProgram({ ...program, color_end: e.target.value })} value={program?.color_end} style={{ width: "100%" }} name="color_end" type="input" id="color_end" autoComplete="off" placeholder="000000" /> */}
                <Box display="flex" flexDirection="row" gap="10px" sx={{ width: "100%" }}>
                  <Box position="relative" flex={1}>
                    <TextField
                      autoComplete="off"
                      name="color_end"
                      placeholder="#000000"
                      id="color_end"
                      value={colorInstanceCreateEnd}
                      fullWidth
                      onChange={(e) => {
                        if (e.target?.value?.length < 8) {
                          setColorInstanceCreateEnd(e.target.value);
                          setProgram({ ...program, color_end: e.target.value });
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              onClick={() => setOpenEnd(!openEnd)}
                              sx={{
                                width: "28px",
                                height: "28px",
                                borderRadius: "6px",
                                bgcolor: `${colorInstanceCreateEnd}`,
                                cursor: "pointer",
                                "&:hover": {
                                  filter: "brightness(0.8)",
                                },
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box display={openEnd ? "flex" : "none"} sx={{ zIndex: "99", position: "absolute", top: "42px", left: "0px" }}>
                      <Colorful
                        disableAlpha
                        color={colorInstanceCreateEnd}
                        onChange={(color) => {
                          setColorInstanceCreateEnd(color.hex);
                          setProgram({ ...program, color_end: color.hex });
                        }}
                        onMouseLeave={() => setOpenEnd(false)}
                      />
                    </Box>
                  </Box>
                </Box>
                <FormikErrorText>{!program?.color_end && addProgramError && "This field is mandatory"}</FormikErrorText>
              </Box>
            </Box>
            <Box>
              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                Description
              </Typography>
              {/* <TextField onChange={(e) => setProgram({ ...program, color_end: e.target.value })} value={program?.color_end} style={{ width: "100%" }} name="color_end" type="input" id="color_end" autoComplete="off" placeholder="000000" /> */}
              <Box display="flex" flexDirection="row" gap="10px" sx={{ width: "100%" }}>
                <Box position="relative" flex={1}>
                  <TextField
                    autoComplete="off"
                    name="desription"
                    multiline
                    rows={3}
                    placeholder="Describe the program"
                    value={program?.description}
                    fullWidth
                    onChange={(e) => {
                      setProgram({ ...program, description: e.target.value });
                    }}
                  />
                </Box>
              </Box>
              <FormikErrorText>{!program?.description && addProgramError && "This field is mandatory"}</FormikErrorText>
            </Box>
            <Box borderRadius="6px" padding="20px" display="flex" flexDirection="column" gap="16px" backgroundColor="#D0CFCF" width="100%">
              <Box>
                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                  Duration
                </Typography>
                <TextField
                  sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                  name="duration"
                  type="number"
                  id="duration"
                  inputProps={{
                    min: 0,
                  }}
                  value={program?.durations?.duration}
                  onChange={(e) => setProgram({ ...program, durations: { ...program?.durations, duration: e.target.value } })}
                  autoComplete="off"
                  placeholder="duration"
                />
                <FormikErrorText>{!program?.durations.duration && addDurationError && "This field is mandatory"}</FormikErrorText>
              </Box>
              <Box borderRadius="6px" padding="20px" display="flex" flexDirection="column" gap="16px" backgroundColor="#929292" width="100%">
                <Typography fontSize="13px" fontWeight="700" color="white">
                  Drop in session
                </Typography>
                <Box>
                  <Typography fontSize="13px" fontWeight="400" color="white">
                    Unit price
                  </Typography>
                  <TextField
                    sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                    name="unit_price"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                    id="unit_price"
                    value={program?.durations?.drop_in_price}
                    onChange={(e) => setProgram({ ...program, durations: { ...program?.durations, drop_in_price: e.target?.value } })}
                    autoComplete="off"
                    placeholder="Unit price"
                  />
                  <FormikErrorText>{!program?.durations.drop_in_price && addDurationError && "This field is mandatory"}</FormikErrorText>
                </Box>
                <Divider sx={{ background: "white" }} />
                <Typography fontSize="13px" fontWeight="700" color="white">
                  Term session
                </Typography>
                <Box>
                  {program?.durations?.frequencies?.map((frequency, index) => (
                    <Box key={index} display="flex" flexDirection="row" gap="25px" flexWrap="wrap">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="white">
                          Frequency (x/week)
                        </Typography>
                        <TextField
                          sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                          name="Frequency"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          value={program?.durations?.frequencies[index]?.frequency_per_week}
                          onChange={(e) => {
                            const updatedFrequencies = [...program?.durations?.frequencies];
                            updatedFrequencies[index] = {
                              ...updatedFrequencies[index],
                              frequency_per_week: e.target?.value,
                            };
                            setProgram({ ...program, durations: { ...program?.durations, frequencies: updatedFrequencies } });
                          }}
                          autoComplete="off"
                          placeholder="Frequency"
                        />
                        <FormikErrorText>{!program?.durations.frequencies[index]?.frequency_per_week && addFrequencyError && "This field is mandatory"}</FormikErrorText>
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="white">
                          Slot price
                        </Typography>
                        <TextField
                          sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                          name="Unit_price"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          value={program?.durations?.frequencies[index]?.price}
                          onChange={(e) => {
                            const updatedFrequencies = [...program?.durations?.frequencies];
                            updatedFrequencies[index] = {
                              ...updatedFrequencies[index],
                              price: e.target?.value,
                            };
                            setProgram({ ...program, durations: { ...program?.durations, frequencies: updatedFrequencies } });
                          }}
                          autoComplete="off"
                          placeholder="Unit price"
                        />
                        <FormikErrorText>{!program?.durations.frequencies[index]?.price && addFrequencyError && "This field is mandatory"}</FormikErrorText>
                      </Box>
                      {index !== 0 && (
                        <Box
                          onClick={() => {
                            let array = program?.durations?.frequencies?.filter((freq) => freq?.id !== frequency?.id);
                            console.log(array, "new Array + removed", frequency?.id);
                            setProgram({ ...program, durations: { ...program?.durations, frequencies: array } });
                          }}
                          display="flex"
                          alignItems="flex-end"
                        >
                          <ButtonSquare action="delete_red" />
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      if (program?.durations.frequencies[program?.durations?.frequencies.length - 1]?.frequency_per_week !== "" && program?.durations.frequencies[program?.durations?.frequencies.length - 1]?.price !== "") {
                        setAddFrequencyError(false);
                        setProgram({ ...program, durations: { ...program?.durations, frequencies: [...program?.durations?.frequencies, { id: program?.durations?.frequencies.length + 1, frequency_per_week: "", price: "" }] } });
                      } else {
                        setAddFrequencyError(true);
                      }
                    }}
                    variant="primary_active"
                  >
                    Add frequency
                  </Button>
                </Box>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    let error = false;
                    if (program?.durations?.duration !== "" && program?.durations?.drop_in_price) {
                      for (let index = 0; index < program?.durations?.frequencies?.length; index++) {
                        const element = program?.durations?.frequencies[index];
                        if (element?.frequency_per_week === "" || element?.price === "") {
                          setAddFrequencyError(true);
                          error = true;
                        }
                      }
                      if (!error) {
                        setAddDurationError(false);
                        setAddFrequencyError(false);
                        setAddedDurations([...addedDurations, program?.durations]);
                        setProgram({
                          ...program,
                          durations: {
                            duration: "",
                            drop_in_price: "",
                            frequencies: [
                              {
                                id: 0,
                                frequency_per_week: "",
                                price: "",
                              },
                            ],
                          },
                        });
                      }
                    } else {
                      setAddDurationError(true);
                    }
                  }}
                  variant="primary_active"
                >
                  Add duration
                </Button>
                {errorAddedDurations && <FormikErrorText>Please save the duration to submit ( click on add durations )</FormikErrorText>}
              </Box>
              {addedDurations?.map((duration, index) => (
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" gap="16px">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Duration
                        </Typography>
                        <TextField
                          sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                          name="duration"
                          inputProps={{
                            min: 0,
                          }}
                          type="number"
                          id="duration"
                          value={duration?.duration}
                          onChange={(e) => {
                            const updatedObject = { ...addedDurations[index], duration: e.target?.value };
                            const updatedList = [...addedDurations.slice(0, index), updatedObject, ...addedDurations.slice(index + 1)];
                            setAddedDurations(updatedList);
                          }}
                          autoComplete="off"
                          placeholder="duration"
                        />
                        <FormikErrorText>{!duration.duration && "This field is mandatory"}</FormikErrorText>
                      </Box>
                      <Box display="flex" alignItems="flex-end">
                        <ButtonSquare
                          onClick={() => {
                            let array = addedDurations?.filter((addedDuration) => addedDuration !== duration);
                            setAddedDurations(array);
                          }}
                          action="delete_red"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box borderRadius="6px" padding="20px" display="flex" flexDirection="column" gap="16px" backgroundColor="#929292" width="100%">
                    <Typography fontSize="13px" fontWeight="700" color="white">
                      Drop in session
                    </Typography>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="white">
                        Unit price
                      </Typography>
                      <TextField
                        sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                        name="unit_price"
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        id="unit_price"
                        value={duration?.drop_in_price}
                        onChange={(e) => {
                          const updatedObject = { ...addedDurations[index], drop_in_price: e.target?.value };
                          const updatedList = [...addedDurations.slice(0, index), updatedObject, ...addedDurations.slice(index + 1)];
                          setAddedDurations(updatedList);
                        }}
                        autoComplete="off"
                        placeholder="Unit price"
                      />
                      <FormikErrorText>{!duration.drop_in_price && "This field is mandatory"}</FormikErrorText>
                    </Box>
                    <Divider sx={{ background: "white" }} />
                    <Typography fontSize="13px" fontWeight="700" color="white">
                      Term session
                    </Typography>
                    <Box>
                      {duration?.frequencies?.map((frequency, indexF) => (
                        <Box key={indexF} display="flex" flexDirection="row" gap="25px" flexWrap="wrap">
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="white">
                              Frequency (x/week)
                            </Typography>
                            <TextField
                              sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                              name="Frequency"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              value={duration?.frequencies[indexF]?.frequency_per_week}
                              onChange={(e) => {
                                const updatedFrequencies = [...duration.frequencies];
                                updatedFrequencies[indexF] = {
                                  ...updatedFrequencies[indexF],
                                  frequency_per_week: e.target?.value,
                                };
                                const updatedObject = { ...addedDurations[index], frequencies: updatedFrequencies };
                                const updatedList = [...addedDurations.slice(0, index), updatedObject, ...addedDurations.slice(index + 1)];
                                setAddedDurations(updatedList);
                              }}
                              autoComplete="off"
                              placeholder="Frequency (x/week)"
                            />
                            <FormikErrorText>{!duration.frequencies[indexF]?.frequency_per_week && "This field is mandatory"}</FormikErrorText>
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="white">
                              Unit price
                            </Typography>
                            <TextField
                              sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                              name="unit_price"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              value={duration.frequencies[indexF]?.price}
                              onChange={(e) => {
                                const updatedFrequencies = [...duration.frequencies];
                                updatedFrequencies[indexF] = {
                                  ...updatedFrequencies[indexF],
                                  price: e.target?.value,
                                };
                                const updatedObject = { ...addedDurations[index], frequencies: updatedFrequencies };
                                const updatedList = [...addedDurations.slice(0, index), updatedObject, ...addedDurations.slice(index + 1)];
                                setAddedDurations(updatedList);
                              }}
                              autoComplete="off"
                              placeholder="Unit price"
                            />
                            <FormikErrorText>{!duration.frequencies[indexF]?.price && "This field is mandatory"}</FormikErrorText>
                          </Box>
                          {indexF !== 0 && (
                            <Box
                              onClick={() => {
                                let array = duration.frequencies?.filter((freq) => freq?.id !== frequency?.id);
                                console.log(array, "new Array + removed", frequency?.id);
                                const newObject = { ...addedDurations[index], frequencies: array };
                                console.log("our new object", newObject);
                                const updatedList = [...addedDurations.slice(0, index), newObject, ...addedDurations.slice(index + 1)];
                                setAddedDurations(updatedList);
                              }}
                              display="flex"
                              alignItems="flex-end"
                            >
                              <ButtonSquare action="delete_red" />
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box marginTop="26px" display="flex" gap="16px" justifyContent="flex-end">
            <Button onClick={() => handleCreateProgram("create")} variant="primary_active">
              Create
            </Button>
            <Button onClick={handleCloseCreateProgram} variant="primary_inactive">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openEditProgram?.open} onClose={handleCloseEditProgram}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseEditProgram} />
          </Box>
          <Box marginBottom="26px">
            <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Update Program
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                Program name
              </Typography>
              <TextField onChange={(e) => setProgram({ ...program, name: e.target.value })} value={program?.name} style={{ width: "100%" }} name="name" type="input" id="name" autoComplete="off" placeholder="program 1" />
              <FormikErrorText>{!program?.name && addProgramError && "This field is mandatory"}</FormikErrorText>
            </Box>
            <Box>
              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                Max number of booking players BY SEANCE
              </Typography>
              <TextField
                inputProps={{
                  min: 0,
                }}
                onChange={(e) => setProgram({ ...program, max_players: e.target.value })}
                value={program?.max_players}
                style={{ width: "100%" }}
                name="max_players"
                type="number"
                id="max_players"
                autoComplete="off"
                placeholder="number of booking players"
              />
              <FormikErrorText>{!program?.max_players && addProgramError && "This field is mandatory"}</FormikErrorText>
            </Box>
            <Box>
              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                Level
              </Typography>

              <Autocomplete
                sx={{ minWidth: "300px" }}
                options={levels}
                multiple
                value={program?.levels}
                isOptionEqualToValue={(option, value) => option?.id === value.id}
                getOptionLabel={(e) => e?.name}
                onChange={(event, value) => {
                  if (value !== null) {
                    setProgram({ ...program, levels: value });
                  } else {
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select levels" />}
              />
              <FormikErrorText>{program?.levels?.length === 0 && addProgramError && "This field is mandatory"}</FormikErrorText>
            </Box>
            <Box display="flex" flexDirection="row" gap="16px" flexWrap="wrap">
              <Box>
                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                  Start color
                </Typography>
                {/* <TextField onChange={(e) => setProgram({ ...program, color_start: e.target.value })} value={program?.color_start} style={{ width: "100%" }} name="color_start" type="input" id="color_start" autoComplete="off" placeholder="ffffff" /> */}
                <Box display="flex" flexDirection="row" gap="10px" sx={{ width: "100%" }}>
                  <Box position="relative" flex={1}>
                    <TextField
                      autoComplete="off"
                      name="color_start"
                      placeholder="#000000"
                      id="color_start"
                      value={colorInstanceCreateStartUpdate}
                      fullWidth
                      onChange={(e) => {
                        if (e.target?.value?.length < 8) {
                          setColorInstanceCreateStartUpdate(e.target.value);
                          setProgram({ ...program, color_start: e.target.value });
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              onClick={() => setOpenStartUpdate(!openStartUpdate)}
                              sx={{
                                width: "28px",
                                height: "28px",
                                borderRadius: "6px",
                                bgcolor: `${colorInstanceCreateStartUpdate}`,
                                cursor: "pointer",
                                "&:hover": {
                                  filter: "brightness(0.8)",
                                },
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box display={openStartUpdate ? "flex" : "none"} sx={{ zIndex: "99", position: "absolute", top: "42px", left: "0px" }}>
                      <Colorful
                        disableAlpha
                        color={colorInstanceCreateStartUpdate}
                        onChange={(color) => {
                          setColorInstanceCreateStartUpdate(color.hex);
                          setProgram({ ...program, color_start: color.hex });
                        }}
                        onMouseLeave={() => setOpenStartUpdate(false)}
                      />
                    </Box>
                  </Box>
                </Box>
                <FormikErrorText>{!program?.color_start && addProgramError && "This field is mandatory"}</FormikErrorText>
              </Box>
              <Box>
                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                  End color
                </Typography>
                {/* <TextField onChange={(e) => setProgram({ ...program, color_end: e.target.value })} value={program?.color_end} style={{ width: "100%" }} name="color_end" type="input" id="color_end" autoComplete="off" placeholder="000000" /> */}
                <Box display="flex" flexDirection="row" gap="10px" sx={{ width: "100%" }}>
                  <Box position="relative" flex={1}>
                    <TextField
                      autoComplete="off"
                      name="color_start"
                      placeholder="#000000"
                      id="color_start"
                      value={colorInstanceCreateEndUpdate}
                      fullWidth
                      onChange={(e) => {
                        if (e.target?.value?.length < 8) {
                          setColorInstanceCreateEndUpdate(e.target.value);
                          setProgram({ ...program, color_end: e.target.value });
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              onClick={() => setOpenEndUpdate(!openEndUpdate)}
                              sx={{
                                width: "28px",
                                height: "28px",
                                borderRadius: "6px",
                                bgcolor: `${colorInstanceCreateEndUpdate}`,
                                cursor: "pointer",
                                "&:hover": {
                                  filter: "brightness(0.8)",
                                },
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box display={openEndUpdate ? "flex" : "none"} sx={{ zIndex: "99", position: "absolute", top: "42px", left: "0px" }}>
                      <Colorful
                        disableAlpha
                        color={colorInstanceCreateEndUpdate}
                        onChange={(color) => {
                          setColorInstanceCreateEndUpdate(color.hex);
                          setProgram({ ...program, color_end: color.hex });
                        }}
                        onMouseLeave={() => setOpenEndUpdate(false)}
                      />
                    </Box>
                  </Box>
                </Box>
                <FormikErrorText>{!program?.color_end && addProgramError && "This field is mandatory"}</FormikErrorText>
              </Box>
            </Box>
            <Box>
              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                Description
              </Typography>
              {/* <TextField onChange={(e) => setProgram({ ...program, color_end: e.target.value })} value={program?.color_end} style={{ width: "100%" }} name="color_end" type="input" id="color_end" autoComplete="off" placeholder="000000" /> */}
              <Box display="flex" flexDirection="row" gap="10px" sx={{ width: "100%" }}>
                <Box position="relative" flex={1}>
                  <TextField
                    autoComplete="off"
                    name="desription"
                    multiline
                    rows={3}
                    placeholder="Describe the program"
                    value={program?.description}
                    fullWidth
                    onChange={(e) => {
                      setProgram({ ...program, description: e.target.value });
                    }}
                  />
                </Box>
              </Box>
              <FormikErrorText>{!program?.description && addProgramError && "This field is mandatory"}</FormikErrorText>
            </Box>
            <Box borderRadius="6px" padding="20px" display="flex" flexDirection="column" gap="16px" backgroundColor="#D0CFCF" width="100%">
              <Box>
                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                  Duration
                </Typography>
                <TextField
                  sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                  name="duration"
                  type="number"
                  id="duration"
                  inputProps={{
                    min: 0,
                  }}
                  value={program?.durations?.duration}
                  onChange={(e) => setProgram({ ...program, durations: { ...program?.durations, duration: e.target.value } })}
                  autoComplete="off"
                  placeholder="duration"
                />
                <FormikErrorText>{!program?.durations.duration && addDurationError && "This field is mandatory"}</FormikErrorText>
              </Box>
              <Box borderRadius="6px" padding="20px" display="flex" flexDirection="column" gap="16px" backgroundColor="#929292" width="100%">
                <Typography fontSize="13px" fontWeight="700" color="white">
                  Drop in session
                </Typography>
                <Box>
                  <Typography fontSize="13px" fontWeight="400" color="white">
                    Unit price
                  </Typography>
                  <TextField
                    sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                    name="unit_price"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                    id="unit_price"
                    value={program?.durations?.drop_in_price}
                    onChange={(e) => setProgram({ ...program, durations: { ...program?.durations, drop_in_price: e.target?.value } })}
                    autoComplete="off"
                    placeholder="Unit price"
                  />
                  <FormikErrorText>{!program?.durations.drop_in_price && addDurationError && "This field is mandatory"}</FormikErrorText>
                </Box>
                <Divider sx={{ background: "white" }} />
                <Typography fontSize="13px" fontWeight="700" color="white">
                  Term session
                </Typography>
                <Box>
                  {program?.durations?.frequencies?.map((frequency, index) => (
                    <Box key={index} display="flex" flexDirection="row" gap="25px" flexWrap="wrap">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="white">
                          Frequency (x/week)
                        </Typography>
                        <TextField
                          sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                          name="Frequency"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          value={program?.durations?.frequencies[index]?.frequency_per_week}
                          onChange={(e) => {
                            const updatedFrequencies = [...program?.durations?.frequencies];
                            updatedFrequencies[index] = {
                              ...updatedFrequencies[index],
                              frequency_per_week: e.target?.value,
                            };
                            setProgram({ ...program, durations: { ...program?.durations, frequencies: updatedFrequencies } });
                          }}
                          autoComplete="off"
                          placeholder="Frequency"
                        />
                        <FormikErrorText>{!program?.durations.frequencies[index]?.frequency_per_week && addFrequencyError && "This field is mandatory"}</FormikErrorText>
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="white">
                          Slot price
                        </Typography>
                        <TextField
                          sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                          name="Unit_price"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          value={program?.durations?.frequencies[index]?.price}
                          onChange={(e) => {
                            const updatedFrequencies = [...program?.durations?.frequencies];
                            updatedFrequencies[index] = {
                              ...updatedFrequencies[index],
                              price: e.target?.value,
                            };
                            setProgram({ ...program, durations: { ...program?.durations, frequencies: updatedFrequencies } });
                          }}
                          autoComplete="off"
                          placeholder="Unit price"
                        />
                        <FormikErrorText>{!program?.durations.frequencies[index]?.price && addFrequencyError && "This field is mandatory"}</FormikErrorText>
                      </Box>
                      {index !== 0 && (
                        <Box
                          onClick={() => {
                            let array = program?.durations?.frequencies?.filter((freq) => freq?.id !== frequency?.id);
                            console.log(array, "new Array + removed", frequency?.id);
                            setProgram({ ...program, durations: { ...program?.durations, frequencies: array } });
                          }}
                          display="flex"
                          alignItems="flex-end"
                        >
                          <ButtonSquare action="delete_red" />
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      if (program?.durations.frequencies[program?.durations?.frequencies.length - 1]?.frequency_per_week !== "" && program?.durations.frequencies[program?.durations?.frequencies.length - 1]?.price !== "") {
                        setAddFrequencyError(false);
                        setProgram({ ...program, durations: { ...program?.durations, frequencies: [...program?.durations?.frequencies, { id: program?.durations?.frequencies.length + 1, frequency_per_week: "", price: "" }] } });
                      } else {
                        setAddFrequencyError(true);
                      }
                    }}
                    variant="primary_active"
                  >
                    Add frequency
                  </Button>
                </Box>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    let error = false;
                    if (program?.durations?.duration !== "" && program?.durations?.drop_in_price) {
                      for (let index = 0; index < program?.durations?.frequencies?.length; index++) {
                        const element = program?.durations?.frequencies[index];
                        if (element?.frequency_per_week === "" || element?.price === "") {
                          setAddFrequencyError(true);
                          error = true;
                        }
                      }
                      if (!error) {
                        setAddDurationError(false);
                        setAddFrequencyError(false);
                        handleAddDurations(program?.durations);
                        setErrorAddedDurations(false);
                      }
                    } else {
                      setAddDurationError(true);
                    }
                  }}
                  variant="primary_active"
                >
                  Add duration
                </Button>
              </Box>
              {addedDurations?.map((duration, index) => (
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" gap="16px">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Duration
                        </Typography>
                        <TextField
                          sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                          name="duration"
                          inputProps={{
                            min: 0,
                          }}
                          type="number"
                          id="duration"
                          value={duration?.duration}
                          onChange={(e) => {
                            const updatedObject = { ...addedDurations[index], duration: e.target?.value };
                            const updatedList = [...addedDurations.slice(0, index), updatedObject, ...addedDurations.slice(index + 1)];
                            setAddedDurations(updatedList);
                          }}
                          autoComplete="off"
                          placeholder="duration"
                        />
                        <FormikErrorText>{!duration.duration && "This field is mandatory"}</FormikErrorText>
                      </Box>
                      {index > 0 && (
                        <Box display="flex" alignItems="flex-end">
                          <ButtonSquare
                            onClick={() => {
                              handleDeleteDuration(duration);
                            }}
                            action="delete_red"
                          />
                        </Box>
                      )}
                      <Box display="flex" alignItems="flex-end">
                        <Button onClick={() => handleSaveDurations(duration)} sx={{ height: "35px" }} variant="primary_active">
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Box borderRadius="6px" padding="20px" display="flex" flexDirection="column" gap="16px" backgroundColor="#929292" width="100%">
                    <Typography fontSize="13px" fontWeight="700" color="white">
                      Drop in session
                    </Typography>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="white">
                        Unit price
                      </Typography>
                      <TextField
                        sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                        name="unit_price"
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        id="unit_price"
                        value={duration?.drop_in_price}
                        onChange={(e) => {
                          const updatedObject = { ...addedDurations[index], drop_in_price: e.target?.value };
                          const updatedList = [...addedDurations.slice(0, index), updatedObject, ...addedDurations.slice(index + 1)];
                          setAddedDurations(updatedList);
                        }}
                        autoComplete="off"
                        placeholder="Unit price"
                      />
                      <FormikErrorText>{!duration.drop_in_price && "This field is mandatory"}</FormikErrorText>
                    </Box>
                    <Divider sx={{ background: "white" }} />
                    <Typography fontSize="13px" fontWeight="700" color="white">
                      Term session
                    </Typography>
                    <Box>
                      {duration?.frequencies?.map((frequency, indexF) => (
                        <Box key={indexF} display="flex" flexDirection="row" alignItems="flex-end" gap="25px" flexWrap="wrap">
                          <Box maxWidth="150px">
                            <Typography fontSize="13px" fontWeight="400" color="white">
                              Frequency (x/week)
                            </Typography>
                            <TextField
                              sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                              name="Frequency"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              value={duration?.frequencies[indexF]?.frequency_per_week}
                              onChange={(e) => {
                                const updatedFrequencies = [...duration.frequencies];
                                updatedFrequencies[indexF] = {
                                  ...updatedFrequencies[indexF],
                                  frequency_per_week: e.target?.value,
                                };
                                const updatedObject = { ...addedDurations[index], frequencies: updatedFrequencies };
                                const updatedList = [...addedDurations.slice(0, index), updatedObject, ...addedDurations.slice(index + 1)];
                                setAddedDurations(updatedList);
                              }}
                              autoComplete="off"
                              placeholder="Frequency (x/week)"
                            />
                            <Box maxWidth="100%">
                              <FormikErrorText>{!duration.frequencies[indexF]?.frequency_per_week && "This field is mandatory"}</FormikErrorText>
                            </Box>
                          </Box>
                          <Box maxWidth="150px">
                            <Typography fontSize="13px" fontWeight="400" color="white">
                              Unit price
                            </Typography>
                            <TextField
                              sx={{ maxWidth: "200px", backgroundColor: "white", borderRadius: "6px" }}
                              name="unit_price"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              value={duration.frequencies[indexF]?.price}
                              onChange={(e) => {
                                const updatedFrequencies = [...duration.frequencies];
                                updatedFrequencies[indexF] = {
                                  ...updatedFrequencies[indexF],
                                  price: e.target?.value,
                                };
                                const updatedObject = { ...addedDurations[index], frequencies: updatedFrequencies };
                                const updatedList = [...addedDurations.slice(0, index), updatedObject, ...addedDurations.slice(index + 1)];
                                setAddedDurations(updatedList);
                              }}
                              autoComplete="off"
                              placeholder="Unit price"
                            />
                            <Box maxWidth="150px">
                              <FormikErrorText>{!duration.frequencies[indexF]?.price && "This field is mandatory"}</FormikErrorText>
                            </Box>
                          </Box>
                          {indexF !== 0 && (
                            <Box
                              onClick={() => {
                                if (duration?.frequencies[indexF]?.new) {
                                  let array = duration.frequencies?.filter((freq) => freq?.id !== frequency?.id);
                                  console.log(array, "new Array + removed", frequency?.id);
                                  const newObject = { ...addedDurations[index], frequencies: array };
                                  console.log("our new object", newObject);
                                  const updatedList = [...addedDurations.slice(0, index), newObject, ...addedDurations.slice(index + 1)];
                                  setAddedDurations(updatedList);
                                } else {
                                  handleDeleteFrequencies(duration?.frequencies[indexF]);
                                }
                              }}
                              display="flex"
                              alignItems="flex-end"
                            >
                              <ButtonSquare action="delete_red" />
                            </Box>
                          )}
                          <Box flex={1} display="flex" alignItems="flex-end">
                            <Button
                              sx={{ height: "35px" }}
                              onClick={() => {
                                if (!duration?.frequencies[indexF]?.new) handleSaveFrequency(duration.frequencies[indexF]);
                                else handleAddFrequencies(duration?.frequencies[indexF], duration?.id);
                              }}
                              variant="primary_active"
                            >
                              Save
                            </Button>
                          </Box>
                        </Box>
                      ))}
                      <Box marginTop="16px">
                        <Button
                          onClick={() => {
                            if (addedDurations[index].frequencies[addedDurations[index].frequencies.length - 1]?.frequency_per_week !== "" && addedDurations[index].frequencies[addedDurations[index].frequencies.length - 1]?.price !== "") {
                              setAddFrequencyError(false);
                              let frequencies = addedDurations[index]?.frequencies;
                              console.log(frequencies, "frequencies");
                              frequencies.push({
                                id: addedDurations[index]?.frequencies?.length,
                                price: "",
                                frequency_per_week: "",
                                new: true,
                              });
                              const updatedObject = { ...addedDurations[index], frequencies: frequencies };
                              console.log(updatedObject, "updated object");

                              const updatedList = [...addedDurations.slice(0, index), updatedObject, ...addedDurations.slice(index + 1)];
                              console.log(updatedList, "updated list");
                              setAddedDurations(updatedList);
                            } else {
                              setAddFrequencyError(true);
                            }
                          }}
                          variant="primary_active"
                        >
                          Add frequency
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box marginTop="26px" display="flex" gap="16px" justifyContent="flex-end">
            <Button onClick={() => handleCreateProgram("update")} variant="primary_active">
              Update
            </Button>
            <Button onClick={handleCloseEditProgram} variant="primary_inactive">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openDeleteProgram?.open} onClose={handleCloseDeleteProgram}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseDeleteProgram} />
          </Box>
          <Box marginBottom="26px">
            <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Delete a program
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
              Are you sure you want to delete this program?
            </Typography>
          </Box>
          <Box marginTop="16px" justifyContent="center" display="flex" gap="16px">
            <Button onClick={handleDeleteProgram} variant="primary_active">
              Delete
            </Button>
            <Button onClick={handleCloseDeleteProgram} variant="primary_inactive">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Programs;
